import _taggedTemplateLiteral from "/opt/src/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
import _slicedToArray from "/opt/src/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _objectSpread from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _objectWithoutProperties from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  color: ", ";\n  cursor: default;\n  padding: 10px 50px 10px 10px;\n  line-height: 16px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  background-color: #fff;\n  border-width: 0 1px 1px;\n  border-style: solid;\n  border-color: #dedede;\n  list-style: none;\n  margin: 0;\n  padding: 0;\n  text-align: left;\n  position: absolute;\n  top: 100%;\n  left: 0;\n  right: 0;\n  z-index: 9999;\n  max-height: 200px;\n  overflow-y: scroll;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  background: none;\n  border: none;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  outline: none;\n\n  &::before {\n    content: \"\";\n    display: block;\n    border-width: 5px 5px 0;\n    border-style: solid;\n    border-color: ", ";\n    transform: ", ";\n    transition: transform 0.2s ease;\n    will-change: transform;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  padding-right: 0;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import React from "react";
import Downshift from "downshift";
import styled from "@emotion/styled";
import { ifProp } from "styled-tools";
import { pickProps } from "@ehealth/utils";
import Field from "./Field";
import FieldView from "./FieldView";
import { InputBorder, Input, InputContent, ErrorMessage } from "./InputField";
var DOWNSHIFT_PROPS = ["itemToString", "selectedItemChanged", "getA11yStatusMessage", "onStateChange", "onInputValueChange", "itemCount", "highlightedIndex", "inputValue", "isOpen", "id", "environment", "onOuterClick"];

var SelectField = function SelectField(_ref) {
  var label = _ref.label,
      horizontal = _ref.horizontal,
      disabled = _ref.disabled,
      dataTestButton = _ref.dataTestButton,
      _ref$items = _ref.items,
      items = _ref$items === void 0 ? [] : _ref$items,
      _ref$filterItems = _ref.filterItems,
      filterItems = _ref$filterItems === void 0 ? function (inputValue, item, index) {
    return true;
  } : _ref$filterItems,
      _ref$renderItem = _ref.renderItem,
      renderItem = _ref$renderItem === void 0 ? function (item) {
    return item;
  } : _ref$renderItem,
      _ref$keyExtractor = _ref.keyExtractor,
      keyExtractor = _ref$keyExtractor === void 0 ? function (item, index) {
    return item.key || index;
  } : _ref$keyExtractor,
      props = _objectWithoutProperties(_ref, ["label", "horizontal", "disabled", "dataTestButton", "items", "filterItems", "renderItem", "keyExtractor"]);

  return React.createElement(DownshiftField, props, function (_ref2) {
    var getRootProps = _ref2.getRootProps,
        getInputProps = _ref2.getInputProps,
        getToggleButtonProps = _ref2.getToggleButtonProps,
        getItemProps = _ref2.getItemProps,
        isOpen = _ref2.isOpen,
        inputValue = _ref2.inputValue,
        highlightedIndex = _ref2.highlightedIndex,
        selectedItem = _ref2.selectedItem,
        openMenu = _ref2.openMenu,
        clearSelection = _ref2.clearSelection,
        _ref2$input = _ref2.input,
        onFocus = _ref2$input.onFocus,
        onBlur = _ref2$input.onBlur,
        size = _ref2$input.size,
        input = _objectWithoutProperties(_ref2$input, ["onFocus", "onBlur", "size"]),
        _ref2$meta = _ref2.meta,
        active = _ref2$meta.active,
        errored = _ref2$meta.errored,
        error = _ref2$meta.error;

    return React.createElement(FieldView, getRootProps({
      refKey: "innerRef",
      label: label,
      horizontal: horizontal
    }), React.createElement(InputBorder, {
      disabled: disabled,
      errored: errored,
      active: active,
      size: size
    }, React.createElement(SelectInput, getInputProps(_objectSpread({}, input, {
      disabled: disabled,
      onFocus: onFocus,
      onBlur: onBlur,
      size: size,
      onKeyDown: function onKeyDown(event) {
        if (!isOpen && event.key === "Backspace") {
          clearSelection();
        }
      }
    }))), React.createElement(DropdownArrow, Object.assign({}, getToggleButtonProps({
      open: isOpen,
      onFocus: onFocus,
      onBlur: function onBlur() {
        if (error) {
          clearSelection();
        }
      },
      disabled: disabled,
      size: size
    }), {
      "data-test": dataTestButton
    }))), errored && React.createElement(ErrorMessage, null, error), isOpen && React.createElement(Dropdown, null, items.filter(function (item, index) {
      return !inputValue || filterItems(inputValue, item, index);
    }).map(function (item, index) {
      var active = item === selectedItem;
      return React.createElement(Option, Object.assign({}, getItemProps({
        item: item,
        active: active,
        highlighted: index === highlightedIndex,
        key: keyExtractor(item, index),
        onClick: function onClick(event) {
          if (active) {
            event.preventDefault();
            clearSelection();
          }
        }
      }), {
        "data-test": item
      }), renderItem(item));
    })));
  });
};

export default SelectField;

var DownshiftField = function DownshiftField(_ref3) {
  var children = _ref3.children,
      _ref3$render = _ref3.render,
      render = _ref3$render === void 0 ? children : _ref3$render,
      props = _objectWithoutProperties(_ref3, ["children", "render"]);

  var _pickProps = pickProps(props, DOWNSHIFT_PROPS),
      _pickProps2 = _slicedToArray(_pickProps, 2),
      downshiftProps = _pickProps2[0],
      fieldProps = _pickProps2[1];

  return React.createElement(Field, Object.assign({
    allowNull: true
  }, fieldProps), function (_ref4) {
    var _ref4$input = _ref4.input,
        value = _ref4$input.value,
        onChange = _ref4$input.onChange,
        input = _objectWithoutProperties(_ref4$input, ["value", "onChange"]),
        fieldRenderProps = _objectWithoutProperties(_ref4, ["input"]);

    return React.createElement(Downshift, Object.assign({}, downshiftProps, {
      selectedItem: value,
      onChange: onChange,
      children: function children(downshiftRenderProps) {
        return render(_objectSpread({}, downshiftRenderProps, fieldRenderProps, {
          input: input
        }));
      }
    }));
  });
};

export var SelectInput = styled(Input)(_templateObject());
export var DropdownArrow = styled(InputContent.withComponent("button"))(_templateObject2(), ifProp("disabled", "#efefef transparent", "#282828 transparent"), ifProp("open", "rotate(180deg)"));
export var Dropdown = styled.ul(_templateObject3());
export var Option = styled.li(_templateObject4(), ifProp("highlighted", "#f5f5f5"), ifProp("active", "#11d8fb"));