import React from "react";

var ChevronLeftIcon = function ChevronLeftIcon(props) {
  return React.createElement("svg", Object.assign({
    viewBox: "0 0 9 19",
    width: "10px",
    height: "20px"
  }, props), React.createElement("path", {
    fill: "#5F9CF2",
    d: "M7.381 18.493l.949-1.188L1.897 9.25 8.33 1.194 7.381.005-.001 9.25z"
  }));
};

export default ChevronLeftIcon;