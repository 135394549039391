import _objectWithoutProperties from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import React from "react";

var SpinnerIcon = function SpinnerIcon(_ref) {
  var _ref$width = _ref.width,
      width = _ref$width === void 0 ? 30 : _ref$width,
      _ref$height = _ref.height,
      height = _ref$height === void 0 ? 30 : _ref$height,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? "#4880ed" : _ref$color,
      props = _objectWithoutProperties(_ref, ["width", "height", "color"]);

  return React.createElement("svg", Object.assign({}, props, {
    width: width,
    height: height,
    viewBox: "0 0 38 38",
    xmlns: "http://www.w3.org/2000/svg",
    stroke: color
  }), React.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, React.createElement("g", {
    transform: "translate(1 1)",
    strokeWidth: "2"
  }, React.createElement("circle", {
    strokeOpacity: ".5",
    cx: "18",
    cy: "18",
    r: "18"
  }), React.createElement("path", {
    d: "M36 18c0-9.94-8.06-18-18-18"
  }, React.createElement("animateTransform", {
    attributeName: "transform",
    type: "rotate",
    from: "0 18 18",
    to: "360 18 18",
    dur: "1s",
    repeatCount: "indefinite"
  })))));
};

export default SpinnerIcon;