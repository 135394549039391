import _objectWithoutProperties from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import React from "react";
import system from "@ehealth/system-components";
import { variant } from "@ehealth/system-tools";

var Svg = function Svg(_ref) {
  var selected = _ref.selected,
      disabled = _ref.disabled,
      props = _objectWithoutProperties(_ref, ["selected", "disabled"]);

  var colorState = disabled ? "disabled" : selected ? "selected" : "inactive";
  var selectedState = selected ? disabled ? "disabled" : "selected" : "inactive";
  return React.createElement("svg", props, React.createElement("defs", null, React.createElement("linearGradient", {
    x1: "50%",
    y1: "100%",
    x2: "50%",
    y2: "0%",
    id: "selected"
  }, React.createElement("stop", {
    "stop-color": "#29B311",
    offset: "0%"
  }), React.createElement("stop", {
    "stop-color": "#57D841",
    offset: "100%"
  })), React.createElement("linearGradient", {
    x1: "50%",
    y1: "100%",
    x2: "50%",
    y2: "0%",
    id: "inactive"
  }, React.createElement("stop", {
    "stop-color": "#F2F4F8",
    offset: "0%"
  }), React.createElement("stop", {
    "stop-color": "#FEFFFF",
    offset: "100%"
  })), React.createElement("linearGradient", {
    x1: "50%",
    y1: "100%",
    x2: "50%",
    y2: "0%",
    id: "disabled"
  }, React.createElement("stop", {
    "stop-color": "#E9EDF1",
    offset: "0%"
  }), React.createElement("stop", {
    "stop-color": "#E9EDF1",
    offset: "100%"
  })), React.createElement("rect", {
    id: "b",
    width: "16",
    height: "16",
    rx: "8"
  })), React.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, React.createElement("use", {
    fill: "url(#".concat(colorState, ")"),
    href: "#b"
  }), React.createElement(Border, {
    state: colorState,
    x: ".5",
    y: ".5",
    width: "15",
    height: "15",
    rx: "7.5"
  }), React.createElement(Selected, {
    state: selectedState,
    cx: "8",
    cy: "8",
    r: "3"
  })));
};

var RadioIcon = system({
  extend: Svg,
  width: "16px",
  height: "16px",
  mr: "15px"
});
var Border = system({
  is: "rect"
}, variant({
  prop: "state",
  key: "radios.border.states"
}));
var Selected = system({
  is: "circle"
}, variant({
  prop: "state",
  key: "radios.circle.states"
}));
export default RadioIcon;