import React from "react";

var ArrowRightIcon = function ArrowRightIcon(props) {
  return React.createElement("svg", Object.assign({
    viewBox: "0 0 61 38",
    fill: "white"
  }, props), React.createElement("path", {
    d: "M.06 21.1v-3.38h54.9L43.42 3.22l1.9-2.4L60.1 19.41 45.32 37.99l-1.9-2.4L54.95 21.1z"
  }));
};

export default ArrowRightIcon;