import _taggedTemplateLiteral from "/opt/src/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  left: 100%;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import React from "react";
import { FieldArray } from "react-final-form-arrays";
import styled from "@emotion/styled";
import { AddIcon, RemoveIcon } from "@ehealth/icons";
import Link from "../Link";
import FieldView from "./FieldView";

var ArrayField = function ArrayField(_ref) {
  var label = _ref.label,
      horizontal = _ref.horizontal,
      name = _ref.name,
      addText = _ref.addText,
      disableAdd = _ref.disableAdd,
      disableRemove = _ref.disableRemove,
      _ref$addButton = _ref.addButton,
      AddButton = _ref$addButton === void 0 ? AddLink : _ref$addButton,
      _ref$removeButton = _ref.removeButton,
      RemoveButtom = _ref$removeButton === void 0 ? RemoveLink : _ref$removeButton,
      children = _ref.children,
      _ref$render = _ref.render,
      render = _ref$render === void 0 ? children : _ref$render;
  return React.createElement(FieldView, {
    label: label,
    horizontal: horizontal,
    wrapperIsLabel: false
  }, React.createElement(FieldArray, {
    name: name
  }, function (_ref2) {
    var fields = _ref2.fields;
    return React.createElement(React.Fragment, null, fields.map(function (name, index) {
      return React.createElement(Item, {
        key: name
      }, render({
        name: name,
        index: index,
        fields: fields
      }), disableRemove || React.createElement(RemoveButtom, {
        icon: React.createElement(RemoveIcon, null),
        index: index,
        onClick: function onClick() {
          return fields.remove(index);
        }
      }));
    }), disableAdd || React.createElement(AddButton, {
      addText: addText,
      onClick: function onClick() {
        return fields.push();
      }
    }));
  }));
};

var AddLink = function AddLink(_ref3) {
  var onClick = _ref3.onClick,
      addText = _ref3.addText;
  return React.createElement(Link, {
    icon: React.createElement(AddIcon, null),
    onClick: onClick,
    size: "xs",
    iconReverse: true,
    upperCase: true,
    bold: true
  }, addText);
};

export default ArrayField;
var Item = styled.div(_templateObject());
var RemoveLink = styled(Link)(_templateObject2());