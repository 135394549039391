import _slicedToArray from "/opt/src/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _toConsumableArray from "/opt/src/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import fromPairs from "lodash/fromPairs";

var convertObjectKeys = function convertObjectKeys(object, converter) {
  var keypath = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

  if (object == null || typeof object !== "object") {
    return object;
  }

  if (Array.isArray(object)) {
    return object.map(function (o, index) {
      return convertObjectKeys(o, converter, _toConsumableArray(keypath).concat([String(index)]));
    });
  }

  return fromPairs(Object.entries(object).map(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    var nestedKeyPath = _toConsumableArray(keypath).concat([key]);

    var convertedKey = converter(key, nestedKeyPath);
    var convertedValue = convertObjectKeys(value, converter, nestedKeyPath);
    return [convertedKey, convertedValue];
  }));
};

export default convertObjectKeys;