import React from "react";
import styled from "@emotion/styled/macro";
import { ifProp } from "styled-tools";
import { Query } from "react-apollo";
import { Match } from "@reach/router";
import { loader } from "graphql.macro";

import { Link } from "@ehealth/components";
import { getFullName } from "@ehealth/utils";

const PersonQuery = loader("../graphql/PersonQuery.graphql");

const UserNav = () => (
  <Query query={PersonQuery}>
    {({ loading, error, data: { person } }) => {
      if (loading || error) return null;
      return (
        <UserNavWrapper>
          <User>{getFullName(person.data)}</User>
          <MenuWrapper>
            <Menu>
              <Match path="/">
                {({ match }) => (
                  <MenuItem to="/" bold={match} color="black">
                    Головна
                  </MenuItem>
                )}
              </Match>
              <Match path="/profile">
                {({ match }) => (
                  <MenuItem to="/profile" bold={match} color="black">
                    Мій профіль
                  </MenuItem>
                )}
              </Match>
              <Match path="/security">
                {({ match }) => (
                  <MenuItem to="/security" bold={match} color="black">
                    Безпека
                  </MenuItem>
                )}
              </Match>
              <MenuItem href="/auth/logout" bold upperCase>
                Вийти
              </MenuItem>
            </Menu>
          </MenuWrapper>
        </UserNavWrapper>
      );
    }}
  </Query>
);

export default UserNav;

const User = styled.div`
  position: relative;
  padding-right: 15px;
  color: #4880ed;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: right;

  &::after {
    position: absolute;
    top: 4px;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: #4880ed transparent transparent transparent;
    content: "";
  }
`;

const Menu = styled.div`
  position: absolute;
  right: 0;
  min-width: 140px;
  padding: 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;

const MenuItem = styled(Link)`
  display: block;
  white-space: nowrap;
  margin-bottom: 15px;
  font-weight: ${ifProp("bold", "700")};

  &:last-child {
    margin-bottom: 0;
  }
`;

const MenuWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1001;
  padding-top: 20px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
`;

const UserNavWrapper = styled.div`
  position: relative;
  cursor: pointer;
  &:hover ${MenuWrapper} {
    visibility: visible;
    opacity: 1;
  }
`;
