import _objectSpread from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectSpread";
import * as system from "styled-system";

var mixed = function mixed(fnOrStyles) {
  return function (props) {
    var theme = props.theme;
    var styles = typeof fnOrStyles === "function" ? fnOrStyles(props) : fnOrStyles;
    return system.mixed(_objectSpread({
      theme: theme
    }, styles));
  };
};

export default mixed;