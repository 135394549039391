import _objectWithoutProperties from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";

var Switch = function Switch(_ref) {
  var value = _ref.value,
      _ref$default = _ref.default,
      defaultCase = _ref$default === void 0 ? null : _ref$default,
      cases = _objectWithoutProperties(_ref, ["value", "default"]);

  return cases[value] || defaultCase;
};

export default Switch;