import React from "react";
import { Link } from "@ehealth/components";
import styled from "@emotion/styled";

import OrderedList from "../components/OrderedList";
import Line from "../components/Line";
import { ArrowRightIcon } from "@ehealth/icons";

const NoDeclarationList = () => (
  <>
    <NoDeclarationText>
      Декларація відсутня. Для підписання декларації, виконайте наступні кроки
    </NoDeclarationText>
    <OrderedList>
      {[
        "Оберіть лікаря",
        "Створіть запит на декларацію",
        "Очікуйте на підтвердження"
      ]}
    </OrderedList>
    <Line />

    <Link
      to="/search"
      fontSize={14}
      fontWeight="bold"
      letterSpacing={2.4}
      style={{ textTransform: "uppercase" }}
      icon={<ArrowRightIcon height="15px" fill="#2292f2" />}
      dataTest="searchLink"
    >
      крок 1. оберіть лікаря
    </Link>
  </>
);

const NoDeclarationText = styled.p`
  text-align: center;
  @media (min-width: 420px) {
    text-align: left;
  }
`;

export default NoDeclarationList;
