import React from "react";
import { Link, Match } from "@reach/router";
import { Flex, Box } from "@rebass/emotion";
import styled from "@emotion/styled";
import { ifProp } from "styled-tools";

import { EhealthLogoIcon } from "@ehealth/icons";
import UserNav from "./UserNav";

const Layout = ({ children }) => (
  <>
    <Wrapper>
      <Header>
        <Link to="/">
          <EhealthLogoIcon height="45" width="101" />
        </Link>
        <NavBlock flexWrap="wrap" alignItems="center" justifyContent="center">
          <Match path="/search">
            {({ match }) => (
              <NavItem to="/search" bold={match}>
                Знайти лікаря
              </NavItem>
            )}
          </Match>
          <Match path="/declarations">
            {({ match }) => (
              <NavItem to="/declarations" bold={match}>
                Декларація
              </NavItem>
            )}
          </Match>
          <UserNav />
        </NavBlock>
      </Header>
      <section>{children}</section>
    </Wrapper>
    <Footer>©{new Date().getFullYear()} Всі права захищені</Footer>
  </>
);

export default Layout;

const Wrapper = styled.section`
  max-width: 960px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 50px 15px 0;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 20px;
  @media (max-width: 450px) {
    justify-content: center;
  }
`;

const Footer = styled.footer`
  flex: 0 0 40px;
  line-height: 40px;
  text-align: center;
  padding: 40px 0;
  border-top: 3px solid #a9da16;
`;

const NavBlock = styled(Flex)`
  margin-left: auto;
  align-items: center;
  @media (max-width: 450px) {
    margin: 20px auto 0;
  }
`;

const NavItem = styled(Link)`
  display: block;
  white-space: nowrap;
  margin-right: 15px;
  color: ${ifProp("bold", "#3c4858", "#7F8FA4")};
  font-weight: ${ifProp("bold", "700")};
`;
