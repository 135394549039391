import React from "react";
import MonthsList from "./MonthsList";
import { Title, Header } from "../Header";
import { MONTH_NAMES } from "../constants";

var MonthPicker = function MonthPicker(_ref) {
  var chooseMonth = _ref.chooseMonth,
      currentMonth = _ref.currentMonth;
  return React.createElement(React.Fragment, null, React.createElement(Header, {
    "data-test": "monthHeader",
    center: true
  }, React.createElement(Title, {
    onClick: function onClick() {
      return chooseMonth(currentMonth);
    }
  }, MONTH_NAMES[currentMonth])), React.createElement(MonthsList, {
    chooseMonth: chooseMonth
  }));
};

export default MonthPicker;