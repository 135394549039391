import React from "react";
import { FormSpy } from "react-final-form";
import { SUBMIT_ERROR } from "../Form";

var SubmitValidation = function SubmitValidation(_ref) {
  var field = _ref.field,
      _ref$entry = _ref.entry,
      entry = _ref$entry === void 0 ? "$.".concat(field) : _ref$entry,
      _ref$rule = _ref.rule,
      rule = _ref$rule === void 0 ? "invalid" : _ref$rule,
      message = _ref.message;
  return React.createElement(FormSpy, {
    subscription: {}
  }, function (_ref2) {
    var form = _ref2.form;
    return React.createElement(FormSpy, {
      subscription: {
        submitErrors: true
      },
      onChange: function onChange(_ref3) {
        var _ref3$submitErrors = _ref3.submitErrors,
            submitErrors = _ref3$submitErrors === void 0 ? {} : _ref3$submitErrors;
        var invalid = submitErrors[SUBMIT_ERROR];
        if (!Array.isArray(invalid)) return;
        var error = invalid.find(function (_ref4) {
          var e = _ref4.entry,
              rules = _ref4.rules;
          return e === entry && rules.some(function (_ref5) {
            var r = _ref5.rule;
            return r === rule;
          });
        });
        if (!error) return;
        form.mutators.setFieldData(field, {
          submitError: message
        });
      }
    });
  });
};

export default SubmitValidation;