import _taggedTemplateLiteral from "/opt/src/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
import _objectWithoutProperties from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  background-color: #f9fafc;\n  border-left: 1px solid #dedede;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding-top: 0;\n  padding-bottom: 0;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  flex: 1 1 auto;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  opacity: 0;\n  height: 0;\n  width: 0;\n  position: absolute;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import React from "react";
import styled from "@emotion/styled";
import Field from "./Field";
import FieldView from "./FieldView";
import { InputBorder, InputContent, InputPlaceholder, ErrorMessage } from "./InputField";
export var FileField = function FileField(_ref) {
  var label = _ref.label,
      horizontal = _ref.horizontal,
      placeholder = _ref.placeholder,
      icon = _ref.icon,
      multiple = _ref.multiple,
      disabled = _ref.disabled,
      props = _objectWithoutProperties(_ref, ["label", "horizontal", "placeholder", "icon", "multiple", "disabled"]);

  return React.createElement(Field, props, function (_ref2) {
    var _ref2$input = _ref2.input,
        value = _ref2$input.value,
        _onChange = _ref2$input.onChange,
        onFocus = _ref2$input.onFocus,
        onBlur = _ref2$input.onBlur,
        _ref2$meta = _ref2.meta,
        active = _ref2$meta.active,
        errored = _ref2$meta.errored,
        error = _ref2$meta.error,
        submitError = _ref2$meta.submitError;
    return React.createElement(FieldView, {
      label: label,
      horizontal: horizontal
    }, React.createElement(Input, {
      type: "file",
      multiple: multiple,
      disabled: disabled,
      onChange: function onChange(event) {
        var files = event.target.files;
        var value = multiple ? Array.from(files) : files[0];

        _onChange(value);
      },
      onFocus: onFocus,
      onBlur: onBlur
    }), React.createElement(InputBorder, {
      disabled: disabled,
      errored: errored,
      active: active
    }, Array.isArray(value) && value.length > 0 || value ? React.createElement(FileLabel, null, multiple ? value.map(function (f) {
      return f.name;
    }).join(", ") : value.name) : React.createElement(InputPlaceholder, null, placeholder), React.createElement(Icon, null, icon)), errored && React.createElement(ErrorMessage, null, error || submitError));
  });
};
export default FileField;
var Input = styled.input(_templateObject());
var FileLabel = styled(InputContent)(_templateObject2());
var Icon = styled(InputContent)(_templateObject3());