import _taggedTemplateLiteral from "/opt/src/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  top: ", ";\n  right: ", ";\n  bottom: ", ";\n  left: ", ";\n  z-index: 1000;\n  min-width: 300px;\n  max-width: 300px;\n  margin: 0 auto;\n  text-align: center;\n  font-size: 14px;\n  color: #354052;\n  background: #fff;\n  box-shadow: 0 2px 4px rgba(72, 60, 60, 0.2);\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from "@emotion/styled";
var Container = styled.div(_templateObject(), function (props) {
  return props.placement === "bottom" ? "100%" : "auto";
}, function (props) {
  return props.placement === "bottom" ? "0" : "auto";
}, function (props) {
  return props.placement === "top" ? "calc(100% + 20px)" : "auto";
}, function (props) {
  return props.placement === "top" ? "0" : "auto";
});
export default Container;