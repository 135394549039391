import _taggedTemplateLiteral from "/opt/src/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: ", "px;\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import React from "react";
import styled from "@emotion/styled";
import { prop } from "styled-tools";
import { Flex, Box } from "@rebass/emotion";
export var Row = styled(Flex)(_templateObject(), prop("theme.form.fieldVerticalDistance", 20));
Row.defaultProps = {
  mx: -2
};
export var Col = function Col(props) {
  return React.createElement(Box, Object.assign({
    px: 2
  }, props));
};