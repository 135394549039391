import _taggedTemplateLiteral from "/opt/src/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  padding: ", ";\n  text-transform: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from "@emotion/styled";
import { prop, ifProp } from "styled-tools";
var List = styled.div(_templateObject(), ifProp("week", "20px 30px 0", "10px 30px 25px"), prop("week", "uppercase"));
export default List;