import _objectWithoutProperties from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import React from "react";
import Dayzed from "dayzed";
import DayPanel from "./DayPanel";
import { Button, Title, Header } from "../Header";
import { MONTH_NAMES } from "../constants";

var DayPicker = function DayPicker(_ref) {
  var offset = _ref.offset,
      increase = _ref.increase,
      decrease = _ref.decrease,
      selected = _ref.selected,
      switchMode = _ref.switchMode,
      getCurrentMonth = _ref.getCurrentMonth,
      props = _objectWithoutProperties(_ref, ["offset", "increase", "decrease", "selected", "switchMode", "getCurrentMonth"]);

  return React.createElement(Dayzed, Object.assign({}, props, {
    offset: offset,
    selected: selected,
    date: selected ? selected : undefined,
    firstDayOfWeek: 1,
    showOutsideDays: true
  }), function (_ref2) {
    var calendars = _ref2.calendars,
        getDateProps = _ref2.getDateProps;
    return calendars.map(function (calendar) {
      return React.createElement(React.Fragment, {
        key: "".concat(calendar.month, "-").concat(calendar.year)
      }, React.createElement(Header, {
        "data-test": "monthYear"
      }, React.createElement(Button, {
        "data-test": "backMonth",
        onMouseDown: function onMouseDown(e) {
          return e.preventDefault();
        },
        onClick: decrease,
        direction: "backward"
      }), React.createElement("div", null, React.createElement(Title, {
        onMouseDown: function onMouseDown(e) {
          return e.preventDefault();
        },
        onClick: function onClick() {
          switchMode("month");
          getCurrentMonth(calendar.month);
        }
      }, MONTH_NAMES[calendar.month], " "), React.createElement(Title, {
        onMouseDown: function onMouseDown(e) {
          return e.preventDefault();
        },
        onClick: function onClick() {
          return switchMode("year");
        }
      }, calendar.year)), React.createElement(Button, {
        "data-test": "forwardMonth",
        onMouseDown: function onMouseDown(e) {
          return e.preventDefault();
        },
        onClick: increase,
        direction: "forward"
      })), React.createElement(DayPanel, {
        calendar: calendar,
        getDateProps: getDateProps
      }));
    });
  });
};

export default DayPicker;