import _objectWithoutProperties from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import React from "react";
import { createPortal } from "react-dom";
import system from "@ehealth/system-components";
import { variant } from "styled-system";
import { CloseIcon } from "@ehealth/icons";

var Modal = function Modal(_ref) {
  var backdrop = _ref.backdrop,
      onClose = _ref.onClose,
      children = _ref.children,
      props = _objectWithoutProperties(_ref, ["backdrop", "onClose", "children"]);

  return createPortal(React.createElement(React.Fragment, null, backdrop && React.createElement(Backdrop, {
    onClick: onClose
  }), React.createElement(Window, props, onClose && React.createElement(CloseButton, {
    onClick: onClose
  }, React.createElement(CloseIcon, null)), children)), window.document.body);
};

export default Modal;
var CloseButton = system({}, {
  position: "absolute",
  top: "28px",
  right: "30px"
});
var Window = system({
  position: "fixed",
  p: 7,
  bg: "white",
  placement: "center"
}, {
  zIndex: 10,
  maxWidth: "100vw",
  maxHeight: "100vh",
  textAlign: "center",
  overflow: "auto",
  boxShadow: "0 0 7px 5px rgba(0, 0, 0, 0.1)"
}, "position", "width", "color", "space", "overflow", "textAlign", variant({
  prop: "placement",
  key: "placements"
}));
var Backdrop = system({
  bg: "rgba(255, 255, 255, 0.5)"
}, {
  position: "fixed",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0
}, "color");