import React from "react";
import { List, Item } from "../Body";

var DayList = function DayList(_ref) {
  var calendar = _ref.calendar,
      getDateProps = _ref.getDateProps;
  return React.createElement(List, {
    "data-test": "calendarDates"
  }, calendar.weeks.map(function (week, windex) {
    return week.map(function (dateObj, index) {
      var key = "".concat(calendar.month, "-").concat(calendar.year, "-").concat(windex, "-").concat(index);

      if (!dateObj) {
        return React.createElement(Item, {
          key: key,
          col: 7
        });
      }

      var date = dateObj.date,
          selected = dateObj.selected,
          selectable = dateObj.selectable,
          today = dateObj.today,
          prevMonth = dateObj.prevMonth,
          nextMonth = dateObj.nextMonth;
      return React.createElement(Item, Object.assign({
        key: key,
        col: 7
      }, getDateProps({
        dateObj: dateObj
      }), {
        selected: selected,
        unavailable: !selectable,
        currentMonth: !(!prevMonth && !nextMonth),
        today: today,
        onMouseDown: function onMouseDown(e) {
          return e.preventDefault();
        }
      }), date.getDate());
    });
  }));
};

export default DayList;