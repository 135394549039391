import _slicedToArray from "/opt/src/node_modules/@babel/runtime/helpers/esm/slicedToArray";

var parseDate = function parseDate(text) {
  var digits = text.replace(/\D/g, ""); // [_matches, year, month, day]

  var _$exec = /^(\d{0,2})(\d{0,2})(\d{0,4})/.exec(digits),
      _$exec2 = _slicedToArray(_$exec, 4),
      day = _$exec2[1],
      month = _$exec2[2],
      year = _$exec2[3];

  return [year, month, day].filter(Boolean).join("-");
};

export default parseDate;