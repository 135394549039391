import _taggedTemplateLiteral from "/opt/src/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  background: none;\n  border: 0 none;\n  &:after {\n    display: block;\n    width: 0;\n    height: 0;\n    border-style: solid;\n    ", ";\n    content: \"\";\n  }\n  &:focus {\n    outline: none;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n        border-width: 6px 10.4px 6px 0;\n        border-color: transparent #ced0da transparent transparent;\n      "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n        border-width: 6px 0 6px 10.4px;\n        border-color: transparent transparent transparent #ced0da;\n      "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from "@emotion/styled";
import { css } from "@emotion/core";

var iconDirection = function iconDirection(props) {
  return props.direction === "forward" ? css(_templateObject()) : css(_templateObject2());
};

var Button = styled.button(_templateObject3(), iconDirection);
export default Button;