import React from "react";

var CheckRightIcon = function CheckRightIcon(props) {
  return React.createElement("svg", Object.assign({}, props, {
    viewBox: "0 0 16 12"
  }), React.createElement("path", {
    fill: "currentColor",
    d: "M.91399 4.9915l2.12137-2.12097 3.74661 3.74583L12.9348.46475l2.0781 2.07766-6.15281 6.15163.24651.24646-2.1214 2.121z"
  }));
};

export default CheckRightIcon;