import _objectSpread from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _slicedToArray from "/opt/src/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _defineProperty from "/opt/src/node_modules/@babel/runtime/helpers/esm/defineProperty";

var filterPropertiesByValue = function filterPropertiesByValue(data, filter) {
  var filterFn = function filterFn(key, value, filter) {
    if (value === filter) {
      return {};
    }

    if (Array.isArray(value)) {
      return _defineProperty({}, key, arrFilter(value, filter));
    }

    if (typeof value === "object" && value !== null) {
      return _defineProperty({}, key, objFilter(value, filter));
    }

    return _defineProperty({}, key, value);
  };

  var objFilter = function objFilter(data, filter) {
    return Object.entries(data).reduce(function (obj, _ref4) {
      var _ref5 = _slicedToArray(_ref4, 2),
          k = _ref5[0],
          v = _ref5[1];

      return _objectSpread({}, obj, filterFn(k, v, filter));
    }, {});
  };

  var arrFilter = function arrFilter(data, filter) {
    return data.map(function (el) {
      return filterPropertiesByValue(el, filter);
    });
  };

  if (Array.isArray(data)) return arrFilter(data, filter);
  if (typeof data === "object" && data !== null) return objFilter(data, filter);
  return data;
};

export default filterPropertiesByValue;