import _taggedTemplateLiteral from "/opt/src/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n                margin-left: auto;\n              "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n                flex-basis: ", "px;\n              "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  @media (min-width: 768px) {\n    ", ";\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  color: #3d3d3d;\n  font-weight: ", ";\n  margin-bottom: 10px;\n  text-align: left;\n  font-size: ", "px;\n  @media (min-width: 768px) {\n    flex-basis: ", ";\n    margin-bottom: ", "px;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  margin-bottom: ", "px;\n  flex-direction: column;\n  align-items: stretch;\n  &:last-child {\n    margin-bottom: 0;\n  }\n  @media (min-width: 768px) {\n    flex-direction: ", ";\n    align-items: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { prop, ifProp, withProp } from "styled-tools";

var FieldView = function FieldView(_ref) {
  var innerRef = _ref.innerRef,
      horizontal = _ref.horizontal,
      label = _ref.label,
      children = _ref.children,
      _ref$wrapperIsLabel = _ref.wrapperIsLabel,
      wrapperIsLabel = _ref$wrapperIsLabel === void 0 ? true : _ref$wrapperIsLabel;
  var Wrapper = wrapperIsLabel ? FieldWrapperLabel : FieldWrapper;
  return React.createElement(Wrapper, {
    ref: innerRef,
    horizontal: horizontal
  }, label && React.createElement(FieldLabel, {
    horizontal: horizontal
  }, label), React.createElement(FieldContent, {
    horizontal: horizontal
  }, children));
};

export default FieldView;
export var FieldWrapper = styled.div(_templateObject(), prop("theme.form.fieldVerticalDistance", 20), ifProp("horizontal", "row", "column"), ifProp("horizontal", "baseline", "stretch"));
export var FieldWrapperLabel = FieldWrapper.withComponent("label");
export var FieldLabel = styled.div(_templateObject2(), prop("theme.input.labelFontWeight", 400), prop("theme.input.labelFontSize", 16), ifProp("horizontal", withProp("theme.input.horizontalLabelWidth", function (b) {
  return b ? "".concat(b, "px") : "auto";
})), ifProp("horizontal", 0, 10));
export var FieldContent = styled.div(_templateObject3(), ifProp("horizontal", withProp("theme.input.horizontalContentWidth", function (width) {
  return width ? css(_templateObject4(), width) : css(_templateObject5());
})));