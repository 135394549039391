import _toArray from "/opt/src/node_modules/@babel/runtime/helpers/esm/toArray";
var PHONE_REGEX = /(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/;

var formatPhone = function formatPhone() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  var digits = value.replace(/[^\d]/g, ""); // [_result, _countryCode, areaCode, ...numberSegments]

  var _PHONE_REGEX$exec = PHONE_REGEX.exec(digits),
      _PHONE_REGEX$exec2 = _toArray(_PHONE_REGEX$exec),
      areaCode = _PHONE_REGEX$exec2[2],
      numberSegments = _PHONE_REGEX$exec2.slice(3);

  var code = ["+38", areaCode].filter(Boolean).join(" (");
  var number = numberSegments.filter(Boolean).join("-");
  return [code, number].filter(Boolean).join(") ");
};

export default formatPhone;