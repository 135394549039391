import _defineProperty from "/opt/src/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectSpread";

var getDefinitions = function getDefinitions(_ref) {
  var data = _ref.data,
      _ref$keyExtractor = _ref.keyExtractor,
      keyExtractor = _ref$keyExtractor === void 0 ? function (item, index) {
    return index;
  } : _ref$keyExtractor,
      renderLabel = _ref.renderLabel,
      renderItem = _ref.renderItem;
  return data.reduce(function (_ref2, item, index) {
    var labels = _ref2.labels,
        items = _ref2.items;
    var key = keyExtractor(item, index);
    return {
      labels: _objectSpread({}, labels, _defineProperty({}, key, renderLabel(item))),
      items: _objectSpread({}, items, _defineProperty({}, key, renderItem(item)))
    };
  }, {
    labels: {},
    items: {}
  });
};

export default getDefinitions;