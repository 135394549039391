import _objectSpread from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _objectWithoutProperties from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import React from "react";
import * as Emotion from "emotion-theming";
import defaultTheme from "./theme";

var ThemeProvider = function ThemeProvider(_ref) {
  var theme = _ref.theme,
      props = _objectWithoutProperties(_ref, ["theme"]);

  return React.createElement(Emotion.ThemeProvider, Object.assign({
    theme: _objectSpread({}, defaultTheme, theme)
  }, props));
};

export default ThemeProvider;