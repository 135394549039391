import _taggedTemplateLiteral from "/opt/src/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n              margin-left: auto;\n            "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n              flex-basis: ", "px;\n            "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  width: 100%;\n  ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  position: absolute;\n  z-index: 2;\n  left: ", ";\n  right: ", ";\n  top: ", ";\n  font-size: ", ";\n  margin-bottom: ", "px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  position: relative;\n  flex-direction: ", ";\n  align-items: ", ";\n  margin-bottom: ", "px;\n  &:last-child {\n    margin-bottom: 0;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { prop, ifProp, withProp } from "styled-tools";

var FieldLabelView = function FieldLabelView(_ref) {
  var innerRef = _ref.innerRef,
      horizontal = _ref.horizontal,
      label = _ref.label,
      children = _ref.children,
      _ref$wrapperIsLabel = _ref.wrapperIsLabel,
      wrapperIsLabel = _ref$wrapperIsLabel === void 0 ? true : _ref$wrapperIsLabel,
      active = _ref.active,
      value = _ref.value;
  var Wrapper = wrapperIsLabel ? FieldWrapperLabel : FieldWrapper;
  return React.createElement(Wrapper, {
    ref: innerRef,
    horizontal: horizontal
  }, label && React.createElement(FieldLabel, {
    active: active,
    value: value,
    horizontal: horizontal
  }, label), React.createElement(FieldContent, {
    horizontal: horizontal
  }, children));
};

export default FieldLabelView;
export var FieldWrapper = styled.div(_templateObject(), ifProp("horizontal", "row", "column"), ifProp("horizontal", "baseline", "stretch"), prop("theme.form.fieldVerticalDistance", 20));
export var FieldWrapperLabel = FieldWrapper.withComponent("label");
export var FieldLabel = styled.div(_templateObject2(), withProp(["active", "value"], function (active, value) {
  return "".concat(!value && !active ? "#9e9e9e" : "#3d3d3d");
}), withProp(["active", "value"], function (active, value) {
  return "".concat(!value && !active ? "25px" : "auto");
}), withProp(["active", "value"], function (active, value) {
  return "".concat(!value && !active ? "auto" : "5px");
}), withProp(["active", "value"], function (active, value) {
  return "".concat(!value && !active ? "14px" : "3px");
}), withProp(["active", "value"], function (active, value) {
  return "".concat(!value && !active ? "16px" : "10px");
}), ifProp("horizontal", 0, 10));
export var FieldContent = styled.div(_templateObject3(), ifProp("horizontal", withProp("theme.input.horizontalContentWidth", function (width) {
  return width ? css(_templateObject4(), width) : css(_templateObject5());
})));