import React from "react";

var CloseIcon = function CloseIcon(props) {
  return React.createElement("svg", Object.assign({
    width: "30",
    height: "30",
    viewBox: "0 0 30 30"
  }, props), React.createElement("path", {
    d: "M28.236 0L15.001 13.235 1.765 0 0 1.764l13.236 13.235L0 28.235 1.765 30l13.236-13.236L28.236 30 30 28.235 16.765 14.999 30 1.764z",
    fill: "currentColor",
    fillRule: "evenodd"
  }));
};

export default CloseIcon;