import React from "react";
import styled from "@emotion/styled";
import { Heading, Button, Link, Switch, Modal } from "@ehealth/components";
import { EhealthLogoIcon, CloseIcon } from "@ehealth/icons";
import { Text } from "@rebass/emotion";

const Error = ({ error, blocking, onClose }) => (
  <Modal width={760} px={76} py={32} placement="center" backdrop>
    {blocking || (
      <CloseButton onClick={onClose}>
        <CloseIcon width={15} height={15} />
      </CloseButton>
    )}
    <Layout>
      <Link href="/">
        <EhealthLogoIcon height="45" />
      </Link>
      <Switch
        value={error.type}
        client={<ErrorDetails text="Сталася помилка. Спробуйте пізніше" />}
        forbidden={
          <Text color="red" fontSize="16" fontWeight="bold">
            У вас немає доступу до даної операції
          </Text>
        }
        network={
          <>
            {blocking ? (
              <ErrorDetails text="Сталася помилка. Спробуйте пізніше" />
            ) : (
              "Щось пішло не так..."
            )}
          </>
        }
        internal_server_error={
          <ErrorDetails number="500" text="Повторіть спробу пізніше" />
        }
        not_found={
          <ErrorDetails number="404" text="Сторінка, яку ви шукаєте відсутня" />
        }
        conflict={
          <ErrorDetails
            number="409"
            text="Сталася помилка. Спробуйте пізніше"
          />
        }
      />
      <Link href="/">
        <Button>Повернутись на головну</Button>
      </Link>
    </Layout>
  </Modal>
);

const ErrorDetails = ({ number, text }) => (
  <>
    {number && (
      <Heading.H1
        fontSize="85px"
        fontWeight={200}
        mb="50px"
        mt="50px"
        lineHeight="85px"
      >
        {number}
      </Heading.H1>
    )}
    <Heading.H2 mb="50px" fontWeight="bold">
      {text}
    </Heading.H2>
  </>
);

export default Error;

const Layout = styled.div`
  text-align: center;
  max-width: 420px;
  margin: 0 auto;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  width: 15px;
  height: 15px;
  cursor: pointer;
`;
