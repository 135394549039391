import React from "react";
import { List, Item } from "../Body";

var YearsList = function YearsList(_ref) {
  var selectedYear = _ref.selectedYear,
      chooseYear = _ref.chooseYear;
  var startYear = selectedYear - 15;
  var yearsArray = Array.from(new Array(16), function (val, index) {
    return index + startYear;
  });
  return React.createElement(List, null, yearsArray.map(function (year) {
    return React.createElement(Item, {
      key: year,
      col: 4,
      onMouseDown: function onMouseDown(e) {
        return e.preventDefault();
      },
      onClick: function onClick() {
        return chooseYear(year);
      }
    }, year);
  }));
};

export default YearsList;