import React from "react";
import Field from "./Field";
import { OnChange } from "react-final-form-listeners";

var ListenerField = function ListenerField(_ref) {
  var field = _ref.field,
      set = _ref.set,
      to = _ref.to,
      becomes = _ref.becomes;
  return React.createElement(Field, {
    name: set,
    subscription: {}
  }, function (_ref2) {
    var onChange = _ref2.input.onChange;
    return React.createElement(OnChange, {
      name: field
    }, function (value) {
      return becomes ? becomes === value && onChange(to) : onChange(to);
    });
  });
};

export default ListenerField;