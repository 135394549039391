import _slicedToArray from "/opt/src/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import React, { Fragment } from "react";

var DefinitionList = function DefinitionList(_ref) {
  var data = _ref.data,
      labels = _ref.labels,
      renderItem = _ref.renderItem,
      _ref$keyExtractor = _ref.keyExtractor,
      keyExtractor = _ref$keyExtractor === void 0 ? function (name) {
    return name;
  } : _ref$keyExtractor;
  return Object.entries(labels).filter(function (_ref2) {
    var _ref3 = _slicedToArray(_ref2, 1),
        name = _ref3[0];

    return Boolean(data[name]);
  }).map(function (_ref4, index) {
    var _ref5 = _slicedToArray(_ref4, 2),
        name = _ref5[0],
        label = _ref5[1];

    return React.createElement(Fragment, {
      key: keyExtractor(name, index)
    }, renderItem({
      label: label,
      value: data[name]
    }));
  });
};

export default DefinitionList;