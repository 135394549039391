import React from "react";

var ChevronRightIcon = function ChevronRightIcon(props) {
  return React.createElement("svg", Object.assign({
    viewBox: "0 0 17 37",
    width: "10px",
    height: "20px"
  }, props), React.createElement("path", {
    fill: "#5F9CF2",
    d: "M2.238 36.987L.339 34.609l12.867-16.11L.339 2.387 2.238.011l14.764 18.488z"
  }));
};

export default ChevronRightIcon;