import _taggedTemplateLiteral from "/opt/src/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
import _objectSpread from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _objectWithoutProperties from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n    width: 34px;\n  "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  visibility: ", ";\n\n  a {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    line-height: 38px;\n    height: 38px;\n    width: 48px;\n  }\n\n  svg {\n    height: 12px;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  border: 1px solid ", ";\n\n  a {\n    text-align: center;\n    text-decoration: none;\n    color: black;\n    display: block;\n    min-width: 26px;\n    font-size: 12px;\n    line-height: 20px;\n    padding: 4px;\n  }\n\n  &:hover {\n    border: 1px solid #eee;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  margin-left: 10px;\n  text-align: center;\n  user-select: none;\n\n  &:first-of-type {\n    margin-left: 0;\n  }\n\n  &:hover {\n    font-weight: 700;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 30px;\n  margin-bottom: 30px;\n  list-style: none;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import React from "react";
import { Link } from "@reach/router";
import styled from "@emotion/styled";
import { withProps } from "recompose";
import { stringifySearchParams } from "@ehealth/utils";
import { ChevronLeftIcon, ChevronRightIcon } from "@ehealth/icons";
import LocationParams from "./LocationParams";
import Pager from "./Pager";

var Pagination = function Pagination(_ref) {
  var totalPages = _ref.totalPages;
  return React.createElement(LocationParams, null, function (_ref2) {
    var page = _ref2.locationParams.page;
    return React.createElement(Pager, {
      currentPage: parseInt(page, 10),
      totalPages: totalPages
    }, function (_ref3) {
      var getPageProps = _ref3.getPageProps,
          prevPage = _ref3.prevPage,
          currentPage = _ref3.currentPage,
          nextPage = _ref3.nextPage,
          leftPages = _ref3.leftPages,
          middlePages = _ref3.middlePages,
          rightPages = _ref3.rightPages,
          isFirstPage = _ref3.isFirstPage,
          isLastPage = _ref3.isLastPage,
          isLeftRangeTruncated = _ref3.isLeftRangeTruncated,
          isRightRangeTruncated = _ref3.isRightRangeTruncated;
      return React.createElement(Container, null, React.createElement(DirectionPage, Object.assign({}, getPageProps({
        page: prevPage
      }, true), {
        hide: isFirstPage,
        backward: true
      })), leftPages.map(function (page) {
        return React.createElement(Page, Object.assign({}, getPageProps({
          page: page,
          key: page
        }, true), {
          selected: page === currentPage
        }));
      }), isLeftRangeTruncated && React.createElement(Delimiter, null), middlePages.map(function (page) {
        return React.createElement(Page, Object.assign({}, getPageProps({
          page: page,
          key: page
        }, true), {
          selected: page === currentPage
        }));
      }), isRightRangeTruncated && React.createElement(Delimiter, null), rightPages.map(function (page) {
        return React.createElement(Page, Object.assign({}, getPageProps({
          page: page,
          key: page
        }, true), {
          selected: page === currentPage
        }));
      }), React.createElement(DirectionPage, Object.assign({}, getPageProps({
        page: nextPage
      }, true), {
        hide: isLastPage
      })));
    });
  });
};

export default Pagination;

var Page = function Page(_ref4) {
  var page = _ref4.page,
      props = _objectWithoutProperties(_ref4, ["page"]);

  return React.createElement(PageItem, props, React.createElement(PageLink, {
    page: page
  }));
};

var DirectionPage = function DirectionPage(_ref5) {
  var page = _ref5.page,
      backward = _ref5.backward,
      props = _objectWithoutProperties(_ref5, ["page", "backward"]);

  return React.createElement(DirectionItem, props, React.createElement(PageLink, {
    page: page
  }, backward ? React.createElement(ChevronLeftIcon, null) : React.createElement(ChevronRightIcon, null)));
};

var PageLink = function PageLink(_ref6) {
  var page = _ref6.page,
      children = _ref6.children;
  return React.createElement(LocationParams, null, function (_ref7) {
    var locationParams = _ref7.locationParams;
    return React.createElement(Link, {
      to: "?".concat(stringifySearchParams(_objectSpread({}, locationParams, {
        page: page
      })))
    }, children || page);
  });
};

var Container = styled.ul(_templateObject());
var Item = styled.li(_templateObject2());
var PageItem = styled(Item)(_templateObject3(), function (props) {
  return props.selected && "#f7f6f6";
}, function (props) {
  return props.selected ? "#eee" : "#cbcbcb";
});
var DirectionItem = styled(PageItem)(_templateObject4(), function (props) {
  return props.hide && "hidden";
});
var Delimiter = withProps({
  children: "..."
})(styled(Item)(_templateObject5()));