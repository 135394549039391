import _taggedTemplateLiteral from "/opt/src/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
import _objectSpread from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _objectWithoutProperties from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  padding: 1px;\n  vertical-align: middle;\n  width: 15px;\n  height: 15px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import React from "react";
import styled from "@emotion/styled";

var CircleIcon = function CircleIcon(_ref) {
  var fill = _ref.fill,
      _ref$stroke = _ref.stroke,
      stroke = _ref$stroke === void 0 ? "#c9f032" : _ref$stroke,
      _ref$strokeWidth = _ref.strokeWidth,
      strokeWidth = _ref$strokeWidth === void 0 ? "5" : _ref$strokeWidth,
      rest = _objectWithoutProperties(_ref, ["fill", "stroke", "strokeWidth"]);

  return React.createElement(Svg, Object.assign({
    viewBox: "0 0 30 30"
  }, rest), React.createElement("circle", {
    cx: "15",
    cy: "15",
    r: "15",
    style: _objectSpread({
      stroke: stroke,
      strokeWidth: strokeWidth,
      fill: fill || "none",
      verticalAlign: "middle"
    }, rest)
  }));
};

var Svg = styled.svg(_templateObject());
export default CircleIcon;