import _taggedTemplateLiteral from "/opt/src/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  width: calc((100% / ", ") - 4px);\n  border: none;\n  margin: 2px;\n  user-select: none;\n  height: ", ";\n  border-radius: 50%;\n  color: ", ";\n  font-size: ", ";\n  background: ", ";\n  cursor: pointer;\n  &:focus {\n    outline: 0;\n  }\n  &:hover {\n    color: ", ";\n  }\n  &[disabled] {\n    background: ", ";\n    color: ", ";\n    cursor: not-allowed;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from "@emotion/styled";
import { prop, ifProp, ifNotProp } from "styled-tools";
var Item = styled.button(_templateObject(), prop("col"), ifNotProp("weekday", "30px"), ifProp("selected", "#fff", ifProp("currentMonth", "#9aa0a9"), ifProp("today", "#2292f2"), ifProp("weekday", "#9aa0a9")), ifProp("weekday", "10px"), ifProp("selected", "#1A91EB"), ifProp("selected", "#fff", "#2292f2"), ifProp("selected", "#1A91EB", "#eaeaea"), ifProp("selected", "#fff", "#333"));
export default Item;