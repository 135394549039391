import _taggedTemplateLiteral from "/opt/src/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: ", ";\n  padding: 15px;\n  border-bottom: 1px solid #dfe3e9;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from "@emotion/styled";
import { ifProp } from "styled-tools";
var Header = styled.div(_templateObject(), ifProp("center", "center", "space-between"));
export default Header;