import _objectSpread from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _slicedToArray from "/opt/src/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _objectWithoutProperties from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import * as React from "react";
import * as FinalForm from "react-final-form";
import { pickProps } from "@ehealth/utils";
var FINAL_FORM_FIELD_PROPS = ["allowNull", "format", "formatOnBlur", "isEqual", "name", "parse", "validate", "validateFields", "subscription", "value"];
var FIELD_STATES = ["disabled", "errored", "active"];

var Field = function Field(_ref) {
  var children = _ref.children,
      _ref$render = _ref.render,
      _render = _ref$render === void 0 ? children : _ref$render,
      type = _ref.type,
      props = _objectWithoutProperties(_ref, ["children", "render", "type"]);

  var _pickProps = pickProps(props, FINAL_FORM_FIELD_PROPS),
      _pickProps2 = _slicedToArray(_pickProps, 2),
      fieldProps = _pickProps2[0],
      inputProps = _pickProps2[1];

  if (typeof _render !== "function") {
    return null;
  }

  return React.createElement(FinalForm.Field, Object.assign({}, fieldProps, {
    type: type,
    render: function render(_ref2) {
      var input = _ref2.input,
          meta = _ref2.meta;
      return _render({
        input: _objectSpread({}, inputProps, input, {
          type: type
        }),
        meta: _objectSpread({}, meta, {
          state: getFieldState(inputProps, meta),
          errored: isErrored(meta),
          error: getError(meta)
        })
      });
    }
  }));
};

export default Field;

var getFieldState = function getFieldState(inputProps, meta) {
  var disabled = inputProps.disabled;
  var active = meta.active;
  var errored = isErrored(meta);
  var flags = [disabled, errored, active];
  return FIELD_STATES.find(function (_name, index) {
    return flags[index];
  });
};

var isErrored = function isErrored(_ref3) {
  var error = _ref3.error,
      submitError = _ref3.submitError,
      _ref3$data = _ref3.data,
      data = _ref3$data === void 0 ? {} : _ref3$data,
      touched = _ref3.touched,
      dirtySinceLastSubmit = _ref3.dirtySinceLastSubmit;
  return error && touched || (submitError || data.submitError) && !dirtySinceLastSubmit;
};

var getError = function getError(_ref4) {
  var error = _ref4.error,
      submitError = _ref4.submitError,
      _ref4$data = _ref4.data,
      data = _ref4$data === void 0 ? {} : _ref4$data;
  return error || submitError || data.submitError;
};