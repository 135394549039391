import React from "react";
import styled from "@emotion/styled";
import { Query } from "react-apollo";
import { Router, Match } from "@reach/router";
import { differenceInYears } from "date-fns";
import { loader } from "graphql.macro";
import { Flex, Box } from "@rebass/emotion";

import {
  Heading,
  Field,
  Form,
  Link,
  Spinner,
  LocationParams,
  Pagination,
  Tooltip,
  Validation
} from "@ehealth/components";
import { getFullName, normalizeName, checkValidDoctor } from "@ehealth/utils";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import debounce from "lodash/debounce";

import DictionaryValue from "../components/DictionaryValue";
import AddressView from "../components/AddressView";
import CabinetTable from "../components/CabinetTable";
import Map from "../components/Map";
import { PERSON_NAME_PATTERN } from "../helpers/constants";

const SettlementQuery = loader("../graphql/SettlementQuery.graphql");
const SearchEmployeeQuery = loader("../graphql/SearchEmployeeQuery.graphql");
const PersonDetailsQuery = loader("../graphql/PersonDetailsQuery.graphql");
const SPECIALITY_TYPE = {
  THERAPIST: "Терапевт",
  PEDIATRICIAN: "Педіатр",
  FAMILY_DOCTOR: "Сімейний лікар"
};

const SearchPage = () => (
  <div data-test="search">
    <Flex justifyContent="space-between" alignItems="center" mb={4}>
      <Box width={100} />
      <Heading.H1>Крок 1. Оберіть лікаря</Heading.H1>
      <Box>
        <Match path="/search/map">
          {props =>
            !props.match ? (
              <Link to="/search/map" replace>
                Обрати на мапі
              </Link>
            ) : (
              <Link to="/search" replace>
                Обрати зі списку
              </Link>
            )
          }
        </Match>
      </Box>
    </Flex>
    <Router>
      <Search path="/" />
      <DivisionMap path="/map" />
    </Router>
  </div>
);

export default SearchPage;

const Search = () => (
  <>
    <FormSearch />
    <LocationParams>
      {({
        locationParams: {
          fullName = "",
          divisionName = "",
          speciality = "",
          page = "1",
          pageSize = "10",
          settlement: { settlement = "", region: settlementRegion = "" } = {}
        }
      }) => {
        return (
          <Query query={PersonDetailsQuery}>
            {({
              loading,
              error,
              data: { person: { data: { birthDate } = {} } = {} }
            }) => {
              if (loading || error) return <Spinner />;

              const currentPersonYears = differenceInYears(
                new Date(),
                new Date(birthDate)
              );
              return (
                <Query
                  query={SearchEmployeeQuery}
                  fetchPolicy="cache-first"
                  variables={{
                    fullName,
                    divisionName,
                    settlement,
                    settlementRegion,
                    speciality,
                    page,
                    pageSize,
                    legalEntityStatus: "ACTIVE"
                  }}
                  context={{ credentials: "same-origin" }}
                >
                  {({ loading, error, data }) => {
                    if (loading || error) return <Spinner />;
                    const { data: search, paging } = data.search;
                    return !search.length ? (
                      "Нічого не знайдено"
                    ) : (
                      <>
                        <CabinetTable
                          data={search}
                          header={{
                            name: "ПІБ лікаря",
                            job: "Спеціальність",
                            divisionName: "Відділення",
                            address: "Адреса",
                            legalEntityName: "Медзаклад",
                            action: "Дія"
                          }}
                          renderRow={({
                            id,
                            party,
                            division: {
                              id: divisionId,
                              name: divisionName,
                              addresses
                            },
                            legalEntity: { name: legalEntityName }
                          }) => {
                            const {
                              specialities: [{ speciality } = {}] = []
                            } = party;
                            return {
                              name: getFullName(party),
                              job: (
                                <DictionaryValue
                                  name="SPECIALITY_TYPE"
                                  item={speciality}
                                />
                              ),
                              divisionName: (
                                <Link
                                  to={`/division/${divisionId}`}
                                  state={{ backURL: "/search/" }}
                                >
                                  {divisionName}
                                </Link>
                              ),
                              address: <AddressView data={addresses} />,
                              legalEntityName,
                              action: checkValidDoctor(
                                currentPersonYears,
                                speciality
                              ) ? (
                                <Link to={`/employee/${id}`} dataTest="details">
                                  Показати деталі
                                </Link>
                              ) : (
                                <Tooltip
                                  offset={80}
                                  position="top"
                                  renderParent={({ getProps }) => (
                                    <span {...getProps({ refKey: "ref" })}>
                                      <Link disabled dataTest="details">
                                        Показати деталі
                                      </Link>
                                    </span>
                                  )}
                                  renderOverlay={({ active, getProps }) =>
                                    active && (
                                      <TooltipMessage {...getProps()}>
                                        Спеціальність лікаря не відповідає
                                        Вашому профілю
                                      </TooltipMessage>
                                    )
                                  }
                                />
                              )
                            };
                          }}
                          rowKeyExtractor={({ id }) => id}
                        />
                        <Pagination totalPages={paging.totalPages} />
                      </>
                    );
                  }}
                </Query>
              );
            }}
          </Query>
        );
      }}
    </LocationParams>
  </>
);

const FormSearch = () => (
  <LocationParams>
    {({ locationParams, setLocationParams }) => (
      <Form
        onSubmit={() => null /* NOT USED, but required */}
        initialValues={locationParams}
      >
        <Form.AutoSubmit
          onSubmit={values => {
            const { fullName } = values;
            const fullNameRegExp = new RegExp(PERSON_NAME_PATTERN);

            if (fullName && !fullNameRegExp.test(fullName)) return null;

            const clearPage = !isEqual(
              omit(values, ["page"]),
              omit(locationParams, ["page"])
            )
              ? { page: 1 }
              : {};
            return setLocationParams({ ...values, ...clearPage });
          }}
          delay={1000}
        />
        <Flex mx={-2} flexWrap="wrap">
          <Box width={[1, 1 / 2, 1 / 4]} px={2} mb={2}>
            <Query
              query={SettlementQuery}
              fetchPolicy="cache-first"
              variables={{ settlement: "" }}
              context={{ credentials: "same-origin" }}
            >
              {({ loading, error, data: { settlements = {} }, refetch }) => (
                <Field.Select
                  name="settlement"
                  label={<b>Населений пункт</b>}
                  placeholder="Введіть населений пункт"
                  itemToString={item => {
                    if (!item) return "";
                    return typeof item === "string"
                      ? normalizeName(item)
                      : normalizeName(item.settlement);
                  }}
                  items={
                    loading || error
                      ? []
                      : settlements.data.map(
                          ({ name, district, type, region }) => ({
                            area: region || undefined,
                            settlement: name,
                            settlementType: type,
                            region: district || undefined
                          })
                        )
                  }
                  onInputValueChange={debounce(
                    settlement => refetch({ settlement }),
                    500
                  )}
                  renderItem={address => <AddressView data={address} />}
                  size="small"
                />
              )}
            </Query>
          </Box>
          <Box width={[1, 1 / 2, 1 / 4]} px={2} mb={2}>
            <Field.Input
              label={<b>Назва відділення</b>}
              placeholder="Відділення"
              name="divisionName"
              size="small"
            />
          </Box>
          <Box width={[1, 1 / 2, 1 / 4]} px={2} mb={2}>
            <Field.Input
              label={<b>Повне ім&#700;я лікаря</b>}
              placeholder="Прізвище, ім&#700;я, по-батькові"
              name="fullName"
              size="small"
            />
            <Validation.Matches
              options={PERSON_NAME_PATTERN}
              field="fullName"
              message="Некоректне ім&#700;я"
            />
          </Box>
          <Box width={[1, 1 / 2, 1 / 4]} px={2} mb={2}>
            <Field.Select
              name="speciality"
              label={<b>Спеціальність</b>}
              placeholder="Виберіть спеціальність"
              itemToString={item => (item == null ? "" : SPECIALITY_TYPE[item])}
              items={Object.keys(SPECIALITY_TYPE)}
              renderItem={item => SPECIALITY_TYPE[item]}
              size="small"
              dataTestButton="specialities"
            />
          </Box>
        </Flex>
      </Form>
    )}
  </LocationParams>
);

const DivisionMap = () => (
  <LocationParams>
    {({ locationParams, setLocationParams }) => (
      <Map
        locationParams={locationParams}
        setLocationParams={setLocationParams}
      />
    )}
  </LocationParams>
);

const TooltipMessage = styled.div`
  padding: 5px;
  width: 150px;
  border: 1px solid #5893f0;
  box-sizing: content-box;
  border-radius: 3px;
  background: #fff;
  color: #3c4858;
`;
