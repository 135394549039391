var checkValidDoctor = function checkValidDoctor(currentPersonYears, speciality) {
  var PEDIATRICIAN_MAX_YEAR = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 18;
  var WHITE_LIST_PEDIATRICIAN = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : ["PEDIATRICIAN", "FAMILY_DOCTOR"];
  var WHITE_LIST_NON_PEDIATRICIAN = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : ["THERAPIST", "FAMILY_DOCTOR"];

  if (PEDIATRICIAN_MAX_YEAR > currentPersonYears) {
    return WHITE_LIST_PEDIATRICIAN.includes(speciality);
  }

  return WHITE_LIST_NON_PEDIATRICIAN.includes(speciality);
};

export default checkValidDoctor;