import React from "react";
import { MONTH_NAMES } from "../constants";
import { List, Item } from "../Body";

var MonthsList = function MonthsList(_ref) {
  var chooseMonth = _ref.chooseMonth;
  return React.createElement(List, null, MONTH_NAMES.map(function (month, index) {
    return React.createElement(Item, {
      key: index,
      col: 3,
      onMouseDown: function onMouseDown(e) {
        return e.preventDefault();
      },
      onClick: function onClick() {
        return chooseMonth(index);
      }
    }, month);
  }));
};

export default MonthsList;