import React from "react";

var EhealthLogoIcon = function EhealthLogoIcon(props) {
  return React.createElement("svg", Object.assign({}, props, {
    viewBox: "0 0 610 271"
  }), React.createElement("path", {
    fill: "#ffe08b",
    d: "M172.8 1.9c-18.4 0-43.1 5.1-76 30.4C27.7 85.5 2.3 162.8 2.3 202.7c0 31 13.3 66.5 51.3 66.5 26.6 0 62.7-10.1 101.4-57.7 1.3-5.7-2.5-10.8-8.2-10.8-27.9 24.1-43.7 36.7-66.5 36.7-17.1 0-30.4-14.6-30.4-36.1 0-25.3 4.4-45.6 8.9-56.4 91.2-25.3 154-57 154-105.2-.1-16.3-14.7-37.8-40-37.8zm-12.7 19.6c11.4 0 14.6 8.2 14.6 14.6 0 5.7-5.1 24.7-24.7 43.1-23.4 22.2-43.1 34.2-84.9 46.9C72.7 107.7 87.3 78.6 112 52c22.8-24.8 39.2-30.5 48.1-30.5z"
  }), React.createElement("path", {
    fill: "#71cefb",
    d: "M183.8 184H131v-43.9h-23.8v111.8H131V205h52.8v46.9h24V140.1h-24V184zm86.3-15.1c-24.6 0-41.9 18.9-41.9 42.4 0 26 18.6 42.6 43.3 42.6 12.6 0 25.3-3.9 33.4-11.2l-9.4-13.7c-5.2 5-14.8 8-21.6 8-13.7 0-22-8.6-23.3-18.8h60.2v-4.7c-.1-26.5-16.5-44.6-40.7-44.6zm-19.9 34.9c1-8 6.7-17.9 20-17.9 14.1 0 19.4 10.2 20 17.9h-40zm114.7-34.9c-12.7 0-25.5 4-35.4 12.7l8 14.3c6.9-6.4 14.9-9.6 23.6-9.6 10.7 0 17.8 5.4 17.8 13.6v10.9c-5.4-6.4-14.9-9.9-25.7-9.9-12.9 0-28.2 7.2-28.2 26.2 0 18.1 15.3 26.8 28.2 26.8 10.6 0 20.1-3.9 25.7-10.4v8.4h21.3v-52.3c0-23.3-16.9-30.7-35.3-30.7zm14 63.6c-3.5 4.7-10.2 7-17.1 7-8.4 0-15.3-4.4-15.3-11.9 0-7.9 6.9-12.2 15.3-12.2 6.9 0 13.6 2.3 17.1 7v10.1zm46.7 19.4h21.3V140.1h-21.3v111.8zm79.6-16.9c-4.7 0-7.4-3.9-7.4-8.9v-36.6h16.4v-18.6h-16.4v-22.1h-21.5v22.1h-13.4v18.6h13.4v42.3c0 14.4 8 22.1 23 22.1 8.9 0 14.6-2.3 17.8-5.2l-4.5-16.1c-1.2 1.2-4.2 2.4-7.4 2.4zm77.6-66.1c-13.2 0-23.3 6.4-28.5 12.6v-41.4H533v111.8h21.3v-54.5c3.7-4.9 10.2-9.6 18.4-9.6 9.2 0 15.1 3.5 15.1 14.8v49.3h21.3v-57.5c0-15.8-8.5-25.5-26.3-25.5z"
  }));
};

export default EhealthLogoIcon;