import React from "react";
import DayList from "./DayList";
import { List, Item } from "../Body";
import { WEEKDAY_NAMES } from "../constants";

var DayPanel = function DayPanel(_ref) {
  var calendar = _ref.calendar,
      getDateProps = _ref.getDateProps;
  return React.createElement(React.Fragment, null, React.createElement(List, {
    week: true
  }, WEEKDAY_NAMES.map(function (weekday, idx) {
    return React.createElement(Item, Object.assign({
      key: "".concat(calendar.month, "-").concat(calendar.year, "-").concat(weekday, "-").concat(idx)
    }, idx === 0 ? {
      "data-test": "firstDayOfWeek"
    } : {}, {
      col: 7,
      weekday: true,
      onMouseDown: function onMouseDown(e) {
        return e.preventDefault();
      }
    }), weekday);
  })), React.createElement(DayList, {
    calendar: calendar,
    getDateProps: getDateProps
  }));
};

export default DayPanel;