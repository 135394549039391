import _extends from "/opt/src/node_modules/@babel/runtime/helpers/esm/extends";
import React from "react";

var BackIcon = function BackIcon(_ref) {
  var props = _extends({}, _ref);

  return React.createElement("svg", Object.assign({}, props, {
    fill: "currentColor",
    viewBox: "0 0 34 20"
  }), React.createElement("path", {
    d: "M88.75,201H121v2H88.75v-2Zm7.89-8.994L98,193.419,89.731,202,98,210.581l-1.362,1.413L87.005,202Z",
    transform: "translate(-87 -192)"
  }));
};

export default BackIcon;