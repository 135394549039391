import _taggedTemplateLiteral from "/opt/src/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  from {\n    transform: translate(-50%, -100%);\n    opacity: 0;\n  }\n  to {\n    transform: translate(-50%, -50%);\n    opacity: 1;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  from {\n    transform: translate(-50%, -100%);\n    opacity: 0;\n  }\n  to {\n    transform: translate(-50%, 0);\n    opacity: 1;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { keyframes } from "@emotion/core";
var toTopBounce = keyframes(_templateObject());
var toCenterBounce = keyframes(_templateObject2());
export var placements = {
  top: {
    top: 0,
    left: "50%",
    transform: "translate(-50%, 0)",
    animation: "".concat(toTopBounce, " .5s ease forwards")
  },
  center: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    animation: "".concat(toCenterBounce, " .5s ease forwards")
  }
};
var colors = {
  red: "#ff0001"
};
export default {
  placements: placements,
  colors: colors
};