import React from "react";
import styled from "@emotion/styled";
import { TableView } from "@ehealth/components";

type CabinetTableProps = {
  data: { value: React.Node },
  header: { value: React.Node },
  renderRow: React.Node,
  rowKeyExtractor: () => mixed,
  columnKeyExtractor: () => mixed
};

const CabinetTable = ({
  data,
  header,
  renderRow,
  rowKeyExtractor,
  columnKeyExtractor
}: CabinetTableProps) => {
  return (
    <TableView
      data={data}
      header={header}
      renderRow={renderRow}
      rowKeyExtractor={rowKeyExtractor}
      columnKeyExtractor={columnKeyExtractor}
      tableComponent={TableRoot}
      headerComponent={TableHeader}
      bodyComponent={() => (
        <TableBodyView
          columns={header}
          data={data}
          renderRow={renderRow}
          rowKeyExtractor={(item, index) => item.key || index}
          columnKeyExtractor={name => name}
          bodyComponent={TableBody}
          rowComponent={TableRow}
          cellComponent={TableCell}
        />
      )}
      rowComponent={TableRow}
      headerCellComponent={TableHeaderCell}
      cellComponent={TableCell}
    />
  );
};

const TableBodyView = ({
  columns,
  data,
  renderRow,
  rowKeyExtractor,
  columnKeyExtractor,
  bodyComponent: BodyComponent,
  rowComponent: RowComponent,
  cellComponent: CellComponent
}) => {
  return (
    <BodyComponent>
      {data.map((item, index) => {
        const row = renderRow(item, index);
        return (
          <RowComponent key={rowKeyExtractor(item, index)}>
            {Object.entries(columns).map(([key, value], index) => (
              <CellComponent key={columnKeyExtractor(key, index)}>
                <MobileOnlyText>{value}:</MobileOnlyText>
                {row[key]}
              </CellComponent>
            ))}
          </RowComponent>
        );
      })}
    </BodyComponent>
  );
};

export default CabinetTable;

const TableRoot = styled.div`
  @media (min-width: 768px) {
    display: table;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
  }
  margin-top: 10px;
`;

const TableHeader = styled.div`
  @media (min-width: 768px) {
    display: table-header-group;
  }
  display: none;
  font-size: 14px;
  user-select: none;
`;

const TableBody = styled.div`
  @media (min-width: 768px) {
    display: table-row-group;
  }
`;

const TableRow = styled.div`
  @media (min-width: 768px) {
    display: table-row;
    border: none;
    border-bottom: 1px solid #e7e7e9;
    margin-bottom: 0;
    padding: 0;
  }
  border: 1px solid #e7e7e9;
  padding: 20px;
  margin-bottom: 10px;
`;

const TableCell = styled.div`
  @media (min-width: 768px) {
    display: table-cell;
    font-size: 14px;
    line-height: 18px;
    padding: 17px 10px;
    margin-top: 0;
    vertical-align: middle;
  }
  margin-top: 10px;
  font-size: 13px;
  text-align: left;
  &:first-child {
    margin-top: 0;
  }
`;

const TableHeaderCell = styled(TableCell)`
  color: #2d2e39;
  cursor: default;
  font-weight: 700;
  padding: 14px 10px;
  text-transform: uppercase;
`;

const MobileOnlyText = styled.span`
  margin-right: 10px;
  font-weight: bold;
  @media (min-width: 768px) {
    display: none;
  }
`;
