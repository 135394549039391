import _slicedToArray from "/opt/src/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _objectSpread from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _objectWithoutProperties from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import { style, util, compose } from "styled-system";
export var gradientStyle = function gradientStyle(_ref) {
  var cssFunction = _ref.cssFunction,
      options = _objectWithoutProperties(_ref, ["cssFunction"]);

  return function (props) {
    var colors = util.get(props.theme, "colors") || {};
    var transformValue = transformGradient(cssFunction, colors);
    return style(_objectSpread({}, options, {
      transformValue: transformValue
    }))(props);
  };
};

var transformGradient = function transformGradient(cssFunction, colors) {
  return function (value) {
    var args = Array.isArray(value) ? value.map(transformColorStop(colors)).join(", ") : value;
    return "".concat(cssFunction, "(").concat(args, ")");
  };
};

var transformColorStop = function transformColorStop(colors) {
  return function (colorStop) {
    var getColor = function getColor(value) {
      return util.get(colors, value) || value;
    };

    if (Array.isArray(colorStop)) {
      var _colorStop = _slicedToArray(colorStop, 2),
          color = _colorStop[0],
          length = _colorStop[1];

      return [getColor(color), util.px(length)].join(" ");
    } else {
      return getColor(colorStop);
    }
  };
};

export var linearGradient = gradientStyle({
  prop: "linearGradient",
  cssProperty: "backgroundImage",
  cssFunction: "linear-gradient",
  key: "gradients"
});
export var radialGradient = gradientStyle({
  prop: "radialGradient",
  cssProperty: "backgroundImage",
  cssFunction: "radial-gradient",
  key: "gradients"
});
export var repeatingLinearGradient = gradientStyle({
  prop: "repeatingLinearGradient",
  cssProperty: "backgroundImage",
  cssFunction: "repeating-linear-gradient",
  key: "gradients"
});
export var repeatingRadialGradient = gradientStyle({
  prop: "repeatingRadialGradient",
  cssProperty: "backgroundImage",
  cssFunction: "repeating-radial-gradient",
  key: "gradients"
});
export default compose(linearGradient, radialGradient, repeatingLinearGradient, repeatingRadialGradient);