// @flow
import React from "react";
import { Query } from "react-apollo";
import { loader } from "graphql.macro";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { Map as LeafletMap, Marker, Popup, TileLayer } from "react-leaflet";
import styled from "@emotion/styled";
import { Link } from "@ehealth/components";
import { ArrowRightIcon } from "@ehealth/icons";
import { stringifySearchParams } from "@ehealth/utils";
import AddressView from "../components/AddressView";
import type {
  URLSearchParams,
  SetLocationParamsProp
} from "@ehealth/components";

type MapProps = {
  locationParams: URLSearchParams,
  setLocationParams: SetLocationParamsProp
};

const SearchDivisionsByMapQuery = loader(
  "../graphql/SearchDivisionsByMapQuery.graphql"
);

const map = React.createRef();

const DEFAULT_CENTER = { lat: 50.4021368, lng: 30.4525107 };
const DEFAULT_ZOOM = 10;

export default function Map({ locationParams, setLocationParams }: MapProps) {
  const { _northEast, _southWest } =
    (map &&
      map.current &&
      map.current.leafletElement &&
      map.current.leafletElement.getBounds()) ||
    {};

  const { lat: north = 50.6207168, lng: east = 31.098175 } = _northEast || {};
  const { lat: south = 50.1830541, lng: west = 29.8072814 } = _southWest || {};

  return (
    <MapContainer>
      <LeafletMap
        center={center(locationParams)}
        zoom={zoom(locationParams)}
        maxZoom={16}
        onViewportChanged={({ center: [lat, lng], zoom }) =>
          setLocationParams({ lat, lng, zoom }, "replace")
        }
        animate
        ref={map}
      >
        <Query
          query={SearchDivisionsByMapQuery}
          variables={{
            north,
            east,
            south,
            west,
            page: 1,
            pageSize: 50
          }}
        >
          {({ error, data }) => {
            if (error || !data.divisions) return null;
            const { data: divisions } = data.divisions;
            return (
              <>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <MarkerClusterGroup>
                  {filteredDivisions(divisions).map(
                    (
                      { coordinates: { latitude, longitude }, ...division },
                      key
                    ) => (
                      <Marker position={[latitude, longitude]} key={key}>
                        <Popup>
                          <SearchMapTooltip
                            {...division}
                            locationParams={locationParams}
                          />
                        </Popup>
                      </Marker>
                    )
                  )}
                </MarkerClusterGroup>
              </>
            );
          }}
        </Query>
      </LeafletMap>
    </MapContainer>
  );
}

const MapContainer = styled.div`
  & > .leaflet-container {
    height: 500px;
  }
`;

const filteredDivisions = divisions =>
  divisions.filter(
    item => item.coordinates.latitude && item.coordinates.longitude
  );

const center = locationParams => {
  let { lat, lng } = locationParams;
  [lat, lng] = [lat, lng].map(n => parseFloat(n));

  return [lat, lng].every(v => !isNaN(v)) ? { lat, lng } : DEFAULT_CENTER;
};
const zoom = locationParams => {
  const { zoom } = locationParams;
  return parseInt(zoom, 10) || DEFAULT_ZOOM;
};

const SearchMapTooltip = ({
  id,
  name,
  legalEntity,
  addresses,
  contacts: {
    phones: [phone]
  },
  locationParams
}) => {
  const [residence] = addresses.filter(a => a.type === "RESIDENCE");
  const backURL =
    stringifySearchParams(locationParams) &&
    `/search/map/?${stringifySearchParams(locationParams)}`;
  return (
    <>
      <Title>
        {name} ({legalEntity.name})
      </Title>
      <AddressView data={residence} />
      <Phone>Тел.: {phone.number}</Phone>
      <Link
        to={`/division/${id}`}
        icon={<ArrowRightIcon height="14px" fill="#2292f2" />}
        state={{
          backURL: backURL || "/search/map/"
        }}
      >
        Детальніше
      </Link>
    </>
  );
};

const Title = styled.b`
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Phone = styled.div`
  margin: 5px 0;
`;
