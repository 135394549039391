import _slicedToArray from "/opt/src/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import * as React from "react";

var TableView = function TableView(_ref) {
  var data = _ref.data,
      header = _ref.header,
      _ref$renderRow = _ref.renderRow,
      renderRow = _ref$renderRow === void 0 ? function (item) {
    return item;
  } : _ref$renderRow,
      _ref$rowKeyExtractor = _ref.rowKeyExtractor,
      rowKeyExtractor = _ref$rowKeyExtractor === void 0 ? function (item, index) {
    return item && item.key || index;
  } : _ref$rowKeyExtractor,
      _ref$columnKeyExtract = _ref.columnKeyExtractor,
      columnKeyExtractor = _ref$columnKeyExtract === void 0 ? function (name) {
    return name;
  } : _ref$columnKeyExtract,
      _ref$tableComponent = _ref.tableComponent,
      TableComponent = _ref$tableComponent === void 0 ? "table" : _ref$tableComponent,
      _ref$tableHeader = _ref.tableHeader,
      TableHeaderView = _ref$tableHeader === void 0 ? TableHeader : _ref$tableHeader,
      _ref$tableBody = _ref.tableBody,
      TableBodyView = _ref$tableBody === void 0 ? TableBody : _ref$tableBody,
      _ref$headerComponent = _ref.headerComponent,
      headerComponent = _ref$headerComponent === void 0 ? "thead" : _ref$headerComponent,
      _ref$bodyComponent = _ref.bodyComponent,
      bodyComponent = _ref$bodyComponent === void 0 ? "tbody" : _ref$bodyComponent,
      _ref$rowComponent = _ref.rowComponent,
      rowComponent = _ref$rowComponent === void 0 ? "tr" : _ref$rowComponent,
      _ref$headerCellCompon = _ref.headerCellComponent,
      headerCellComponent = _ref$headerCellCompon === void 0 ? "th" : _ref$headerCellCompon,
      _ref$cellComponent = _ref.cellComponent,
      cellComponent = _ref$cellComponent === void 0 ? "td" : _ref$cellComponent,
      sortableFields = _ref.sortableFields,
      sortingParams = _ref.sortingParams,
      onSortingChange = _ref.onSortingChange,
      _ref$filterRow = _ref.filterRow,
      filterRow = _ref$filterRow === void 0 ? null : _ref$filterRow,
      onFilter = _ref.onFilter,
      whiteSpaceNoWrap = _ref.whiteSpaceNoWrap,
      headless = _ref.headless;
  return React.createElement(TableComponent, {
    headless: headless
  }, !headless && React.createElement(TableHeaderView, {
    header: header,
    columnKeyExtractor: columnKeyExtractor,
    headerComponent: headerComponent,
    rowComponent: rowComponent,
    headerCellComponent: headerCellComponent,
    sortableFields: sortableFields,
    sortingParams: sortingParams,
    onSortingChange: onSortingChange,
    filterRow: filterRow,
    onFilter: onFilter
  }), React.createElement(TableBodyView, {
    columns: Object.keys(header),
    data: data,
    renderRow: renderRow,
    rowKeyExtractor: rowKeyExtractor,
    columnKeyExtractor: columnKeyExtractor,
    bodyComponent: bodyComponent,
    rowComponent: rowComponent,
    cellComponent: cellComponent,
    filterRow: filterRow,
    whiteSpaceNoWrap: whiteSpaceNoWrap
  }));
};

export default TableView;

var TableHeader = function TableHeader(_ref2) {
  var header = _ref2.header,
      columnKeyExtractor = _ref2.columnKeyExtractor,
      HeaderComponent = _ref2.headerComponent,
      RowComponent = _ref2.rowComponent,
      HeaderCellComponent = _ref2.headerCellComponent;
  return React.createElement(HeaderComponent, null, React.createElement(RowComponent, null, Object.entries(header).map(function (_ref3, index) {
    var _ref4 = _slicedToArray(_ref3, 2),
        name = _ref4[0],
        content = _ref4[1];

    return React.createElement(HeaderCellComponent, {
      key: columnKeyExtractor(name, index)
    }, content);
  })));
};

var TableBody = function TableBody(_ref5) {
  var columns = _ref5.columns,
      data = _ref5.data,
      renderRow = _ref5.renderRow,
      rowKeyExtractor = _ref5.rowKeyExtractor,
      columnKeyExtractor = _ref5.columnKeyExtractor,
      BodyComponent = _ref5.bodyComponent,
      RowComponent = _ref5.rowComponent,
      CellComponent = _ref5.cellComponent;
  return React.createElement(BodyComponent, null, data.map(function (item, index) {
    var row = renderRow(item, index);
    return React.createElement(RowComponent, {
      key: rowKeyExtractor(item, index)
    }, columns.map(function (name, index) {
      return React.createElement(CellComponent, {
        key: columnKeyExtractor(name, index)
      }, row[name]);
    }));
  }));
};