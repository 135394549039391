import _taggedTemplateLiteral from "/opt/src/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: ", ";\n  background-color: #2292f2;\n  background-image: linear-gradient(\n    0deg,\n    rgba(0, 134, 242, 0.67) 0%,\n    rgba(74, 178, 251, 0.67) 100%\n  );\n  color: #fff;\n  cursor: default;\n  font-weight: 700;\n  font-size: ", ";\n  letter-spacing: ", "px;\n  width: ", ";\n  padding: 16px 20px;\n  text-align: center;\n  text-transform: uppercase;\n  text-decoration: none;\n  user-select: none;\n  @media (min-width: 768px) {\n    padding: ", "px\n      ", "px;\n    font-size: ", ";\n  }\n  &:hover {\n    background-image: linear-gradient(\n      0deg,\n      rgba(74, 178, 251, 0.67) 0%,\n      rgba(74, 178, 251, 0.67) 100%\n    );\n  }\n\n  &:active {\n    background-image: none;\n  }\n\n  &:disabled {\n    background-color: rgba(34, 146, 242, 0.5);\n    background-image: linear-gradient(\n      0deg,\n      rgba(0, 134, 242, 0.34) 0%,\n      rgba(74, 178, 251, 0.34) 100%\n    );\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import React from "react";
import * as Reach from "@reach/router";
import styled from "@emotion/styled";
import { prop, ifProp, switchProp } from "styled-tools";

var Button = function Button(props) {
  var Component = ButtonContainer.withComponent(props.href ? "a" : props.to ? Reach.Link : "button");
  return React.createElement(Component, props);
};

export default Button;
var ButtonContainer = styled.button(_templateObject(), ifProp("block", "block", "inline-block"), switchProp("size", {
  xs: prop("theme.button.sizes.xs", "10px"),
  small: prop("theme.button.sizes.small", "12px"),
  medium: prop("theme.button.sizes.medium", "16px"),
  large: prop("theme.button.sizes.large", "20px")
}), prop("theme.button.letterSpacing", 2.4), ifProp("block", "100%"), prop("theme.button.paddingVertical", 18), prop("theme.button.paddingHorizontal", 35), switchProp("size", {
  xs: prop("theme.button.sizes.xs", "10px"),
  small: prop("theme.button.sizes.small", "14px"),
  medium: prop("theme.button.sizes.medium", "18px"),
  large: prop("theme.button.sizes.large", "22px")
}));