import React from "react";
import * as Rebass from "@rebass/emotion";

var H1 = function H1(props) {
  return React.createElement(Heading, Object.assign({
    as: "h1",
    textAlign: "center",
    style: {
      textTransform: "uppercase",
      transition: "font-size .1s linear"
    },
    fontSize: [16, 18, 20, 22]
  }, props));
};

var H2 = function H2(props) {
  return React.createElement(Heading, Object.assign({
    as: "h2",
    fontWeight: 300,
    fontSize: [16, 18],
    style: {
      textTransform: "uppercase",
      transition: "font-size .1s linear"
    }
  }, props));
};

var H3 = function H3(props) {
  return React.createElement(Heading, Object.assign({
    as: "h3",
    fontSize: [14, 16],
    fontWeight: 400
  }, props));
};

var H4 = function H4(props) {
  return React.createElement(Heading, Object.assign({
    as: "h4",
    fontSize: [13, 14],
    fontWeight: 400
  }, props));
};

var H5 = function H5(props) {
  return React.createElement(Heading, Object.assign({
    as: "h5",
    fontSize: [13, 14],
    fontWeight: 400
  }, props));
};

var H6 = function H6(props) {
  return React.createElement(Heading, Object.assign({
    as: "h6",
    fontSize: 12
  }, props));
};

var Heading = function Heading(props) {
  return React.createElement(Rebass.Heading, props);
};

Heading.H1 = H1;
Heading.H2 = H2;
Heading.H3 = H3;
Heading.H4 = H4;
Heading.H5 = H5;
Heading.H6 = H6;
export default Heading;