import _objectWithoutProperties from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import React from "react";

var AddIcon = function AddIcon(_ref) {
  var innerRef = _ref.innerRef,
      props = _objectWithoutProperties(_ref, ["innerRef"]);

  return React.createElement("svg", Object.assign({}, props, {
    ref: innerRef,
    width: "28",
    height: "28",
    viewBox: "0 0 550 550"
  }), React.createElement("circle", {
    r: "256",
    fill: "#27cc6c",
    cy: "273.69",
    cx: "275"
  }), React.createElement("path", {
    fill: "#FFF",
    d: "M403 235.4h-88.4V147h-79.2v88.4H147v79.2h88.4V403h79.2v-88.4H403z"
  }));
};

export default AddIcon;