import _taggedTemplateLiteral from "/opt/src/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
import _objectWithoutProperties from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  background-color: #ff1f44;\n  color: #fff;\n  font-size: 10px;\n  line-height: 1.4;\n  padding: 8px 15px 7px 13px;\n  position: absolute;\n  top: 100%;\n  right: 0;\n  z-index: 3;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  padding-left: 0;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  resize: ", ";\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  color: #9e9e9e;\n  flex: 1 1 auto;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  background: none;\n  border: none;\n  width: 100%;\n  overflow: hidden;\n  color: inherit;\n  flex: 1 1 auto;\n  font: inherit;\n  line-height: inherit;\n  outline: none;\n\n  &:disabled {\n    -webkit-text-fill-color: inherit;\n  }\n\n  &::placeholder {\n    color: #9e9e9e;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  padding: ", ";\n  position: relative;\n  text-align: left;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background-color: #fefefe;\n  border: 1px solid\n    ", ";\n  color: ", ";\n  display: flex;\n  font-size: ", ";\n  line-height: ", "px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import React from "react";
import styled from "@emotion/styled";
import { prop, ifProp, withProp } from "styled-tools";
import MaskedInput from "react-text-mask";
import { switchFlags } from "@ehealth/utils";
import Field from "./Field";
import FieldView from "./FieldView";
import FieldLabelView from "./FieldLabelView";
export var TextField = function TextField(props) {
  return React.createElement(InputField, Object.assign({}, props, {
    type: "text"
  }));
};
export var MultilineTextField = function MultilineTextField(props) {
  return React.createElement(InputField, Object.assign({}, props, {
    inputComponent: Textarea
  }));
};
export var NumberField = function NumberField(props) {
  return React.createElement(InputField, Object.assign({}, props, {
    type: "number"
  }));
};
export var PasswordField = function PasswordField(props) {
  return React.createElement(InputField, Object.assign({}, props, {
    type: "password"
  }));
};
export var MaskField = function MaskField(props) {
  return React.createElement(InputField, Object.assign({}, props, {
    inputComponent: MaskContent
  }));
};
export var InputField = function InputField(_ref) {
  var label = _ref.label,
      horizontal = _ref.horizontal,
      size = _ref.size,
      prefix = _ref.prefix,
      postfix = _ref.postfix,
      disabled = _ref.disabled,
      color = _ref.color,
      placeholder = _ref.placeholder,
      _ref$inputComponent = _ref.inputComponent,
      InputComponent = _ref$inputComponent === void 0 ? Input : _ref$inputComponent,
      _ref$fieldComponent = _ref.fieldComponent,
      FieldComponent = _ref$fieldComponent === void 0 ? placeholder && !label ? FieldLabelView : FieldView : _ref$fieldComponent,
      props = _objectWithoutProperties(_ref, ["label", "horizontal", "size", "prefix", "postfix", "disabled", "color", "placeholder", "inputComponent", "fieldComponent"]);

  return React.createElement(Field, props, function (_ref2) {
    var input = _ref2.input,
        _ref2$meta = _ref2.meta,
        active = _ref2$meta.active,
        errored = _ref2$meta.errored,
        error = _ref2$meta.error;
    return React.createElement(FieldComponent, {
      label: label || placeholder,
      horizontal: horizontal,
      active: active,
      value: !!input.value
    }, React.createElement(InputBorder, {
      disabled: disabled,
      errored: errored,
      active: active,
      size: size,
      color: color
    }, prefix && React.createElement(InputContent, null, prefix), React.createElement(InputComponent, Object.assign({}, input, {
      placeholder: label ? placeholder : null,
      disabled: disabled,
      size: size
    })), postfix && React.createElement(InputContent, null, postfix)), errored && React.createElement(ErrorMessage, null, error));
  });
};
export var InputBorder = styled.div(_templateObject(), switchFlags({
  disabled: "#efefef",
  errored: "#ff1f44",
  active: "#11d8fb"
}, "#dedede"), withProp(["color", "disabled"], function (color, disabled) {
  return "".concat(color ? color : disabled ? "efefef" : "#3d3d3d");
}), withProp([prop("size", "medium"), prop("theme.input.fontSize", {
  small: 14,
  medium: 16
})], function (size, fontSize) {
  return "".concat(fontSize[size], "px");
}), prop("theme.input.lineHeight", 22));
export var InputContent = styled.div(_templateObject2(), withProp([prop("size", "medium"), prop("theme.input.paddingTop", {
  small: 6,
  medium: 14
}), prop("theme.input.paddingHorizontal", {
  small: 19,
  medium: 25
}), prop("theme.input.paddingBottom", {
  small: 6,
  medium: 14
})], function (size) {
  for (var _len = arguments.length, paddings = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    paddings[_key - 1] = arguments[_key];
  }

  return paddings.map(function (padding) {
    return "".concat(padding[size], "px");
  }).join(" ");
}));
export var Input = styled(InputContent.withComponent("input"))(_templateObject3());
export var InputPlaceholder = styled(InputContent)(_templateObject4());
export var Textarea = styled(Input.withComponent("textarea"))(_templateObject5(), ifProp("noResize", "none"));
export var MaskContent = function MaskContent(props) {
  return React.createElement(InputContent, null, React.createElement(MaskedInputField, props));
};
var MaskedInputField = styled(MaskedInput)(_templateObject6());
export var ErrorMessage = styled.div(_templateObject7());