import _taggedTemplateLiteral from "/opt/src/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
import _slicedToArray from "/opt/src/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _objectWithoutProperties from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n      margin-left: 7px;\n    "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n      margin-right: 7px;\n    "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex: 0 0 auto;\n\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  cursor: pointer;\n  display: inline-flex;\n  align-items: center;\n  opacity: ", ";\n  padding: 0;\n  text-decoration: none;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import React from "react";
import * as Reach from "@reach/router";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Text } from "@rebass/emotion";
import { prop, ifProp, switchProp } from "styled-tools";
import { pickValidProps } from "@ehealth/utils";

var Link = function Link(_ref) {
  var state = _ref.state,
      props = _objectWithoutProperties(_ref, ["state"]);

  var _pickValidProps = pickValidProps(props),
      _pickValidProps2 = _slicedToArray(_pickValidProps, 2),
      _pickValidProps2$ = _pickValidProps2[0],
      children = _pickValidProps2$.children,
      size = _pickValidProps2$.size,
      to = _pickValidProps2$.to,
      linkProps = _objectWithoutProperties(_pickValidProps2$, ["children", "size", "to"]),
      _pickValidProps2$2 = _pickValidProps2[1],
      icon = _pickValidProps2$2.icon,
      iconReverse = _pickValidProps2$2.iconReverse;

  var Component = LinkContainer.withComponent(props.href ? "a" : props.to ? Reach.Link : "button");
  var content = [React.createElement(Text, Object.assign({
    key: "text"
  }, linkProps), children), icon && React.createElement(Icon, {
    key: "icon",
    iconReverse: iconReverse
  }, icon)];
  return React.createElement(Component, Object.assign({}, linkProps, {
    to: to,
    state: state,
    type: !(props.href || props.to) ? "button" : undefined,
    "data-test": props.dataTest
  }), iconReverse ? content.reverse() : content);
};

export default Link;
var LinkContainer = styled.a(_templateObject(), switchProp(prop("color", "blue"), {
  red: prop("theme.link.colors.red", "#ff1751"),
  blue: prop("theme.link.colors.blue", "#2292f2"),
  black: prop("theme.link.colors.black", "#333"),
  white: prop("theme.link.colors.white", "#fff")
}), ifProp("disabled", "0.5"));
var Icon = styled.span(_templateObject2(), ifProp("iconReverse", css(_templateObject3()), css(_templateObject4())));