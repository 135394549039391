import React from "react";
import * as ReachRouter from "@reach/router";
import system from "@ehealth/system-components";
import { gradient, boolean } from "@ehealth/system-tools";
var Nav = system({
  is: "nav",
  mb: 4,
  repeatingLinearGradient: [["transparent", ["transparent", 59], ["januaryDawn", 59], ["januaryDawn", 60]]],
  blacklist: ["repeatingLinearGradient"]
}, {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: ["center", "space-around"]
}, gradient, "space", "justifyContent", "repeatingLinearGradient");
var Item = system({
  fontSize: 2,
  mx: [4, 6],
  color: "blueberrySoda",
  blacklist: ["active"]
}, {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  textDecoration: "none",
  whiteSpace: "nowrap",
  height: 60,
  borderBottomWidth: 3,
  borderBottomStyle: "solid",
  borderColor: "transparent"
}, boolean({
  prop: "active",
  borderColor: "rockmanBlue",
  color: "darkAndStormy"
}), "space", "color", "fontSize", "borderBottom", "borderColor");

var Link = function Link(props) {
  return React.createElement(ReachRouter.Match, {
    path: props.to
  }, function (_ref) {
    var match = _ref.match;
    return React.createElement(Item, Object.assign({
      extend: ReachRouter.Link
    }, props, {
      active: !!match
    }));
  });
};

var Tabs = {
  Link: Link,
  Item: Item,
  Nav: Nav
};
export default Tabs;