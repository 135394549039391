import _objectSpread from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _defineProperty from "/opt/src/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { FORM_ERROR } from "final-form";
import set from "lodash/set";
import normalizeInputErrors from "./normalizeInputErrors";

var normalizeErrors = function normalizeErrors(errors, prefix) {
  var normalizedErrors = errors.reduce(function (acc, _ref) {
    var message = _ref.message,
        _ref$extensions = _ref.extensions,
        code = _ref$extensions.code,
        exception = _ref$extensions.exception;
    var error = exception && exception.inputErrors ? normalizeInputErrors(exception.inputErrors, prefix) : _defineProperty({}, FORM_ERROR, message);
    return _objectSpread({}, acc, error);
  }, {});
  return prefix ? set({}, prefix, normalizedErrors) : normalizedErrors;
};

export default normalizeErrors;