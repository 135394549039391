import _defineProperty from "/opt/src/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _slicedToArray from "/opt/src/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { unflatten } from "flat";

var parseSearchParams = function parseSearchParams(queryString) {
  var parseParams = Array.from(new URLSearchParams(queryString).entries()).reduce(function (params, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        name = _ref2[0],
        value = _ref2[1];

    return _objectSpread({}, params, _defineProperty({}, name, value));
  }, {});
  return unflatten(parseParams);
};

export default parseSearchParams;