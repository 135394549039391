import React, { createContext, Component } from "react";
import Error from "./components/Error";

import env from "./env";

const { Provider, Consumer } = createContext(() => {});

export default class ErrorBoundary extends Component {
  static Consumer = Consumer;

  state = { error: null, blocking: false };

  componentDidCatch({ message }, info) {
    const error = { type: "client", message };

    this.setError({ error, blocking: true });
  }

  render() {
    const { error, blocking } = this.state;

    return (
      <Provider value={this.setError}>
        {error && (
          <Error error={error} blocking={blocking} onClose={this.onClose} />
        )}
        {(error && blocking) || this.props.children}
      </Provider>
    );
  }

  setError = ({ error, blocking }) => {
    if (error.type === "unauthorized") {
      const authUrl = `${env.REACT_APP_OAUTH_URL}?client_id=${
        env.REACT_APP_CLIENT_ID
      }&redirect_uri=${env.REACT_APP_OAUTH_REDIRECT_URI}`;
      window.location.replace(authUrl);
      return;
    }
    this.setState({ error, blocking });
  };

  onClose = () => {
    this.setState({ error: null, blocking: false });
  };
}
