import _toConsumableArray from "/opt/src/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/opt/src/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/opt/src/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _slicedToArray from "/opt/src/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import React, { Children, cloneElement } from "react";
import { Field } from "react-final-form";
import validator from "validator";
import capitalize from "lodash/capitalize";
import { differenceInCalendarYears, differenceInCalendarDays, isAfter, isBefore, isSameDay, isWithinRange } from "date-fns";
import SubmitValidation from "./SubmitValidation";
var VENDOR_BLACKLIST_FNS = ["isEmpty", "blacklist", "escape", "unescape", "ltrim", "normalizeEmail", "rtrim", "stripLow", "toBoolean", "toDate", "toFloat", "toInt", "trim", "whitelist"];
var VENDOR_VALIDATORS = Object.entries(validator).filter(function (_ref) {
  var _ref2 = _slicedToArray(_ref, 1),
      name = _ref2[0];

  return !VENDOR_BLACKLIST_FNS.includes(name);
}).reduce(function (validators, _ref3) {
  var _ref4 = _slicedToArray(_ref3, 2),
      name = _ref4[0],
      fn = _ref4[1];

  var componentName = /^is/.test(name) ? name.replace(/^is/, "") : capitalize(name);
  return _objectSpread({}, validators, _defineProperty({}, componentName, function (value) {
    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    return !value || fn.apply(void 0, [value].concat(args));
  }));
}, {});
var CUSTOM_VALIDATORS = {
  Required: function Required(value) {
    return Array.isArray(value) && value.length > 0 || typeof value === "number" && !isNaN(value) || Boolean(value);
  },
  Date: function (_Date) {
    function Date(_x) {
      return _Date.apply(this, arguments);
    }

    Date.toString = function () {
      return _Date.toString();
    };

    return Date;
  }(function (value) {
    var date = Date.parse(value);
    return !value || !isNaN(date) && new Date(date).toISOString().startsWith(value);
  }),
  MinDate: function MinDate(value, minDate) {
    return !value || isAfter(new Date(value), new Date(minDate));
  },
  MaxDate: function MaxDate(value, _ref5) {
    var max = _ref5.max,
        min = _ref5.min;
    return !value || isSameDay(new Date(value), new Date(max)) || isWithinRange(new Date(value), new Date(min), new Date(max));
  },
  IsExists: function IsExists(value) {
    for (var _len2 = arguments.length, options = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
      options[_key2 - 1] = arguments[_key2];
    }

    return !options.includes(value);
  },
  BirthDate: function BirthDate(value) {
    var differenceInDays = differenceInCalendarDays(new Date(), new Date(value));
    var differenceInYears = differenceInCalendarYears(new Date(), new Date(value));
    return differenceInDays >= 0 && differenceInYears < 150;
  },
  Custom: function Custom(value, resultFromOptions) {
    return resultFromOptions;
  },
  ZeroOrPositive: function ZeroOrPositive(value) {
    return typeof value === "undefined" || parseInt(value) >= 0;
  },
  PositiveFloat: function PositiveFloat(value) {
    return typeof value === "undefined" || value === null || parseFloat(value) > 0;
  }
};

var PREDEFINED_VALIDATORS = _objectSpread({}, VENDOR_VALIDATORS, CUSTOM_VALIDATORS);

var Validation = function Validation(_ref6) {
  var field = _ref6.field,
      _validate = _ref6.validate,
      validateFields = _ref6.validateFields,
      message = _ref6.message,
      options = _ref6.options;
  return React.createElement(Field, {
    name: field,
    validate: function validate(value, allValues) {
      if (typeof options === "function") {
        options = options({
          value: value,
          allValues: allValues
        });
      }

      if (typeof message === "function") {
        message = message({
          value: value,
          allValues: allValues,
          options: options
        });
      }

      return _validate(value, allValues, options) ? undefined : message;
    },
    validateFields: validateFields,
    subscription: {},
    component: Noop
  });
};

var createValidationComponents = function createValidationComponents(baseComponent, validators) {
  return Object.entries(validators).reduce(function (components, _ref7) {
    var _ref8 = _slicedToArray(_ref7, 2),
        name = _ref8[0],
        validator = _ref8[1];

    return _objectSpread({}, components, _defineProperty({}, name, createAppliedValidationComponent(baseComponent, name, validator)));
  }, {});
};

var createAppliedValidationComponent = function createAppliedValidationComponent(baseComponent, name, validator) {
  var validate = createValidateFn(validator);

  var AppliedValidation = function AppliedValidation(props) {
    return baseComponent(_objectSpread({}, props, {
      validate: validate
    }));
  };

  AppliedValidation.displayName = "Validation.".concat(name);
  return AppliedValidation;
};

var createValidateFn = function createValidateFn(validator) {
  return function (value, _allValues, options) {
    if (!Array.isArray(options)) options = [options];
    return validator.apply(void 0, [value].concat(_toConsumableArray(options)));
  };
};

Object.assign(Validation, _objectSpread({}, createValidationComponents(Validation, PREDEFINED_VALIDATORS), {
  Submit: SubmitValidation
}));
export default Validation;
export var Validations = function Validations(_ref9) {
  var field = _ref9.field,
      children = _ref9.children;
  return React.createElement(React.Fragment, null, Children.map(children, function (child) {
    return cloneElement(child, {
      field: field
    });
  }));
};

var Noop = function Noop() {
  return null;
};