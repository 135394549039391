import _slicedToArray from "/opt/src/node_modules/@babel/runtime/helpers/esm/slicedToArray";

var formatDate = function formatDate(value) {
  if (typeof value !== "string") return value;
  var digits = value.replace(/\D/g, "").slice(0, 8); // [_matches, year, month, day]

  var _$exec = /(\d{0,4}?)(\d{0,2}?)(\d{0,2}?)$/.exec(digits),
      _$exec2 = _slicedToArray(_$exec, 4),
      year = _$exec2[1],
      month = _$exec2[2],
      day = _$exec2[3];

  return [day, month, year].filter(Boolean).join(".");
};

export default formatDate;