import * as React from "react";
import { Location } from "@reach/router";
import { parseSearchParams, stringifySearchParams, cleanDeep } from "@ehealth/utils";
import isEqual from "lodash/isEqual";

var LocationParams = function LocationParams(_ref) {
  var state = _ref.state,
      children = _ref.children,
      _ref$render = _ref.render,
      render = _ref$render === void 0 ? children : _ref$render;
  return React.createElement(Location, {
    children: function children(_ref2) {
      var location = _ref2.location,
          navigate = _ref2.navigate;
      return render({
        locationParams: parseSearchParams(location.search),
        setLocationParams: function setLocationParams(params) {
          var parsedLocationSearch = parseSearchParams(location.search);
          var parsedParams = cleanDeep(params);

          if (!isEqual(parsedLocationSearch, parsedParams)) {
            var query = stringifySearchParams(parsedParams);
            navigate("".concat(location.pathname, "?").concat(query), {
              state: state
            });
          }
        }
      });
    }
  });
};

export default LocationParams;