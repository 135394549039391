import _taggedTemplateLiteral from "/opt/src/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  margin: 10px auto;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from "@emotion/styled";
import { SpinnerIcon } from "@ehealth/icons";
var Spinner = styled(SpinnerIcon)(_templateObject());
export default Spinner;